define("ember-svg-jar/inlined/tommy-says.bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path d=\"M0 0h366v248H0z\"/>\n    <path fill=\"#0A6C74\" d=\"m0 127 8.7-10.7c8.6-10.6 26-32 43.5-29.5s35.1 28.9 52.6 43c17.5 14.2 34.9 16.2 52.2 17.4 17.3 1.1 34.7 1.5 52-6 17.3-7.5 34.7-22.9 52.2-32.2 17.5-9.3 35.1-12.7 52.6-11.3 17.5 1.3 34.9 7.3 43.5 10.3l8.7 3v137H0V127Z\"/>\n  </g>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 366 248"
    }
  };
});