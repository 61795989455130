define("ember-svg-jar/inlined/medi-kit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M67.145 51.656h-9.239v-9.288a2.813 2.813 0 00-2.805-2.82H45.024a2.813 2.813 0 00-2.806 2.82v9.288H32.98a2.813 2.813 0 00-2.805 2.82v10.13a2.813 2.813 0 002.805 2.821h9.24v9.289a2.813 2.813 0 002.805 2.82H55.1a2.813 2.813 0 002.805-2.82v-9.289h9.239a2.813 2.813 0 002.805-2.82v-10.13a2.813 2.813 0 00-2.805-2.821z\"/>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M92.056 25.152h-3.79v-1.218c0-4.376-3.559-7.935-7.934-7.935a7.884 7.884 0 00-4.023 1.1v-2.822c0-4.527-3.668-8.209-8.176-8.209H31.991c-4.508 0-8.175 3.682-8.175 8.209v3.499a7.897 7.897 0 00-4.997-1.777c-4.376 0-7.935 3.56-7.935 7.935v1.217H8.068C3.619 25.151 0 28.786 0 33.252v52.58c0 4.466 3.62 8.1 8.068 8.1h83.988c4.45 0 8.068-3.634 8.068-8.1v-52.58c0-4.466-3.619-8.1-8.068-8.1zM80.332 19.91a4.028 4.028 0 014.024 4.024V34.61a4.028 4.028 0 01-4.024 4.024 4.028 4.028 0 01-4.023-4.024V23.934a4.028 4.028 0 014.023-4.024zm-52.605-5.633c0-2.37 1.913-4.298 4.264-4.298h36.142c2.351 0 4.264 1.928 4.264 4.298V25.15h-5.745v-7.42c0-1.08-.876-1.955-1.956-1.955H35.428c-1.08 0-1.956.876-1.956 1.956v7.42h-5.745V14.276zm9.657 10.875v-5.464H62.74v5.464H37.384zm-22.589-1.218a4.028 4.028 0 014.024-4.024 4.028 4.028 0 014.023 4.024V34.61a4.028 4.028 0 01-4.023 4.024 4.028 4.028 0 01-4.024-4.024V23.934zM96.213 85.83c0 2.31-1.864 4.19-4.157 4.19H8.068c-2.292 0-4.157-1.88-4.157-4.19V33.252c0-2.31 1.865-4.19 4.157-4.19h2.816v5.548c0 4.375 3.56 7.935 7.935 7.935s7.934-3.56 7.934-7.935v-5.548h45.644v5.548c0 4.375 3.56 7.935 7.935 7.935s7.935-3.56 7.935-7.935v-5.548h3.79c2.291 0 4.156 1.88 4.156 4.19v52.58z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});