define("@cloudcreativity/ember-quill/components/quill", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
    characters = this.characters
    editor=(component "quill-editor"
      id=this.editorId
      onLength=this.setLength
      onWords=this.setWords
      _toolbar=(concat "#" this.toolbarId)
    )
    length=this.length
    toolbar=(component "quill/toolbar"
      id=this.toolbarId
    )
    words=this.words
  )}}
  
  */
  {
    "id": "ubC0cnbC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"characters\",\"editor\",\"length\",\"toolbar\",\"words\"],[[32,0,[\"characters\"]],[30,[36,0],[\"quill-editor\"],[[\"id\",\"onLength\",\"onWords\",\"_toolbar\"],[[32,0,[\"editorId\"]],[32,0,[\"setLength\"]],[32,0,[\"setWords\"]],[30,[36,1],[\"#\",[32,0,[\"toolbarId\"]]],null]]]],[32,0,[\"length\"]],[30,[36,0],[\"quill/toolbar\"],[[\"id\"],[[32,0,[\"toolbarId\"]]]]],[32,0,[\"words\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"concat\",\"hash\"]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill.hbs"
    }
  });
  let QuillComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class QuillComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "length", _descriptor, this);
      _initializerDefineProperty(this, "characters", _descriptor2, this);
      _initializerDefineProperty(this, "words", _descriptor3, this);
    }
    get id() {
      return Ember.guidFor(this);
    }
    get toolbarId() {
      return `${this.id}-toolbar`;
    }
    get editorId() {
      return `${this.id}-editor`;
    }
    setLength(length) {
      this.length = length;
      this.characters = length - 1;
    }
    setWords(words) {
      this.words = words;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "length", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "characters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "words", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setLength", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setLength"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setWords", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setWords"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, QuillComponent);
});