define("ember-svg-jar/inlined/nav.settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"#6D6D6D\" d=\"M13.5 2a.5.5 0 01.49.397l.414 1.97c.447.141.879.32 1.292.536l1.688-1.1a.5.5 0 01.626.065l2.122 2.122a.5.5 0 01.065.626l-1.1 1.688c.215.413.395.845.535 1.292l1.971.415A.5.5 0 0122 10.5v3a.5.5 0 01-.397.49l-1.97.414c-.141.447-.32.879-.536 1.292l1.1 1.688a.5.5 0 01-.065.626l-2.122 2.122a.5.5 0 01-.626.065l-1.688-1.1a7.953 7.953 0 01-1.292.535l-.415 1.971A.5.5 0 0113.5 22h-3a.5.5 0 01-.49-.397l-.414-1.97a7.953 7.953 0 01-1.292-.536l-1.688 1.1a.5.5 0 01-.626-.065L3.868 18.01a.5.5 0 01-.065-.626l1.1-1.688a7.953 7.953 0 01-.535-1.292l-1.971-.415A.5.5 0 012 13.5v-3a.5.5 0 01.397-.49l1.97-.414c.141-.447.32-.879.536-1.292l-1.1-1.688a.5.5 0 01.065-.626L5.99 3.868a.5.5 0 01.626-.065l1.688 1.1a7.953 7.953 0 011.292-.535l.415-1.971A.5.5 0 0110.5 2h3zm-.406 1h-2.188l-.393 1.866a.5.5 0 01-.358.38 6.954 6.954 0 00-1.626.674.5.5 0 01-.521-.016L6.41 4.862 4.862 6.41l1.042 1.598a.5.5 0 01.016.521 6.954 6.954 0 00-.674 1.626.5.5 0 01-.38.358L3 10.906v2.188l1.866.393a.5.5 0 01.38.358c.155.57.381 1.115.674 1.626a.5.5 0 01-.016.521L4.862 17.59l1.548 1.548 1.598-1.042a.5.5 0 01.521-.016c.51.293 1.056.52 1.626.674a.5.5 0 01.358.38L10.906 21h2.188l.393-1.866a.5.5 0 01.358-.38 6.954 6.954 0 001.626-.674.5.5 0 01.521.016l1.598 1.042 1.548-1.548-1.042-1.598a.5.5 0 01-.016-.521c.293-.51.52-1.056.674-1.626a.5.5 0 01.38-.358L21 13.094v-2.188l-1.866-.393a.5.5 0 01-.38-.358 6.954 6.954 0 00-.674-1.626.5.5 0 01.016-.521l1.042-1.598-1.548-1.548-1.598 1.042a.5.5 0 01-.521.016 6.954 6.954 0 00-1.626-.674.5.5 0 01-.358-.38L13.094 3z\"/>\n    <path fill=\"#27CA8B\" d=\"M12 8a4 4 0 110 8 4 4 0 010-8zm0 1a3 3 0 100 6 3 3 0 000-6z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});