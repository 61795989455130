define("ember-math-helpers/helpers/sum", ["exports", "ember-math-helpers/helpers/add"], function (_exports, _add) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sum = sum;
  /**
   * Alias for {{add}} helper
   *
   * ```hbs
   * {{sum a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to sum
   * @return {number} The sum of all the passed numbers
   */
  function sum(numbers) {
    return (0, _add.add)(numbers);
  }
  var _default = _exports.default = Ember.Helper.helper(sum);
});