define("ember-svg-jar/inlined/grad.basal-metabolism", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient id=\"a\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"99.655%\">\n      <stop offset=\"0%\" stop-color=\"#24BBBC\"/>\n      <stop offset=\"100%\" stop-color=\"#B97BE2\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\">\n    <path fill=\"currentColor\" d=\"M31.126 17.817a11.582 11.582 0 0 1 0 4.368h3.363v-4.368h-3.363zm-8.328-2.507c-1.673 0-2.986 1.392-2.986 1.392s-1.238-1.392-2.986-1.392c-1.7 0-3.089 1.567-3.18 3.463-.05 1.072.259 1.887.694 2.626.87 1.476 5.477 4.47 5.477 4.47s4.59-2.981 5.466-4.47c.437-.742.745-1.554.694-2.626-.09-1.896-1.479-3.463-3.18-3.463\"/>\n    <path fill=\"url(#a)\" d=\"M24.604 20c0-4.198-2.114-7.91-5.333-10.132L18.41.708A.781.781 0 0 0 17.631 0H6.973a.781.781 0 0 0-.778.708l-.862 9.16C2.114 12.09 0 15.802 0 20c0 4.198 2.114 7.91 5.333 10.132l.862 9.16a.781.781 0 0 0 .778.708h10.658c.403 0 .74-.307.778-.708l.862-9.16c3.22-2.221 5.333-5.934 5.333-10.132ZM7.684 1.562h9.236l.691 7.342a12.229 12.229 0 0 0-5.309-1.206c-1.9 0-3.7.433-5.309 1.206l.691-7.341Zm9.236 36.876H7.684l-.69-7.342a12.229 12.229 0 0 0 5.308 1.206c1.9 0 3.701-.433 5.31-1.206l-.692 7.341Zm-4.618-7.698c-5.922 0-10.74-4.818-10.74-10.74 0-5.922 4.818-10.74 10.74-10.74 5.922 0 10.74 4.818 10.74 10.74 0 5.922-4.818 10.74-10.74 10.74Z\" transform=\"translate(7.51)\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});