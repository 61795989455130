define("ember-svg-jar/inlined/scales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M67.732 83.958c-6.877-.391-11.34-7.418-8.77-13.81.697-3.032.867-6.162.504-9.252-.597-5.079-1.473-12.452-1.473-12.452-.917-6.784 4.578-12.723 11.412-12.334 6.007.342 10.599 5.487 10.257 11.494l-1.306 26.873c-.316 5.552-5.072 9.797-10.624 9.481zM32.112 83.958c6.877-.391 11.34-7.418 8.77-13.81a27.182 27.182 0 01-.504-9.252c.597-5.079 1.473-12.452 1.473-12.452.917-6.784-4.578-12.723-11.413-12.334-6.006.342-10.598 5.487-10.256 11.494l1.306 26.873c.315 5.552 5.072 9.797 10.624 9.481z\"/>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M83.984 0H15.86C9.958 0 5.156 4.77 5.156 10.634v78.732C5.156 95.229 9.958 100 15.86 100h68.124c5.902 0 10.704-4.77 10.704-10.634V10.634C94.688 4.771 89.886 0 83.983 0zM67.89 3.906c.003.119.004.237.004.355 0 8.784-6.447 16.102-14.896 17.553l3.923-7.96a1.953 1.953 0 00-3.503-1.726l-4.879 9.896c-9.267-.702-16.59-8.4-16.59-17.763 0-.118 0-.236.003-.355H67.89zm22.89 85.46c0 3.71-3.05 6.728-6.798 6.728H15.86c-3.748 0-6.797-3.018-6.797-6.728V10.634c0-3.71 3.05-6.728 6.797-6.728h12.188c-.002.119-.005.237-.005.355 0 11.977 9.815 21.721 21.879 21.721 12.064 0 21.88-9.744 21.88-21.72 0-.119-.004-.237-.006-.356h12.188c3.748 0 6.797 3.018 6.797 6.728v78.732z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});