define("ember-svg-jar/inlined/basketball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M83.81 83.81c-9.71 9.712-22.55 14.387-35.275 14.027-.362-12.724 4.313-25.564 14.025-35.277 9.712-9.712 22.553-14.387 35.277-14.025.36 12.724-4.316 25.565-14.026 35.276zM37.245 37.245c-9.71 9.71-22.552 14.386-35.276 14.026-.362-12.723 4.313-25.564 14.025-35.276C25.706 6.282 38.547 1.607 51.271 1.97c.36 12.723-4.315 25.565-14.026 35.275z\"/>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M99.786 48.48c-.365-12.789-5.549-24.815-14.597-33.864C76.14 5.568 64.115.386 51.327.021a49.675 49.675 0 00-19.53 3.366c-6.46 2.511-12.24 6.289-17.181 11.23-4.94 4.94-8.718 10.72-11.23 17.18a49.717 49.717 0 00-3.365 19.53C.385 64.116 5.568 76.14 14.616 85.189c9.049 9.049 21.075 14.233 33.864 14.597a49.688 49.688 0 0019.529-3.368c6.46-2.512 12.24-6.29 17.18-11.229 4.94-4.94 8.717-10.72 11.228-17.18a49.74 49.74 0 003.369-19.53zM49.856 3.899c.452 0 .906.006 1.36.02 11.138.316 21.647 4.6 29.807 12.106l-31.12 31.12-31.122-31.12c8.53-7.859 19.45-12.126 31.075-12.126zm-33.83 14.883l31.12 31.12-31.121 31.122c-7.506-8.161-11.79-18.67-12.107-29.808-.346-12.131 3.943-23.572 12.107-32.434zM49.94 95.908c-.448 0-.9-.006-1.35-.02A45.805 45.805 0 0118.782 83.78l31.12-31.12 31.121 31.12C72.492 91.64 61.57 95.909 49.94 95.909zm33.84-14.885l-31.12-31.12 31.12-31.121A45.806 45.806 0 0195.888 48.59c.344 12.132-3.945 23.573-12.108 32.433z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});