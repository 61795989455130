define("ember-svg-jar/inlined/plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M74.702 23.181v66.224h-49.92V23.181z\"/>\n    <path fill=\"#FFF\" d=\"M38.178 69.712h23.128\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M78.79 12.587H65.884V9.794A1.953 1.953 0 0063.93 7.84h-4.585C58.437 3.373 54.476 0 49.742 0s-8.694 3.373-9.603 7.84h-4.585a1.953 1.953 0 00-1.953 1.954v2.793H20.694a6.636 6.636 0 00-6.629 6.628v74.157A6.636 6.636 0 0020.694 100H78.79a6.636 6.636 0 006.629-6.628V19.215a6.636 6.636 0 00-6.628-6.628zm-41.283-.84h4.39a1.953 1.953 0 001.953-1.953 5.896 5.896 0 015.892-5.888 5.896 5.896 0 015.892 5.888c0 1.078.875 1.953 1.953 1.953h4.39v9.48h-24.47v-9.48zm44.006 81.625c0 1.5-1.221 2.722-2.723 2.722H20.694a2.725 2.725 0 01-2.723-2.722V19.215a2.725 2.725 0 012.723-2.722H33.6v6.688c0 1.079.875 1.953 1.953 1.953H63.93a1.953 1.953 0 001.953-1.953v-6.688H78.79a2.725 2.725 0 012.723 2.722v74.157z\"/>\n      <path d=\"M61.306 37.522H38.178a1.953 1.953 0 100 3.907h23.128a1.953 1.953 0 000-3.907zM61.306 52.64H38.178a1.953 1.953 0 100 3.907h23.128a1.953 1.953 0 000-3.907zM61.306 67.758H38.178a1.953 1.953 0 100 3.907h23.128a1.953 1.953 0 000-3.907z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});