define("ember-svg-jar/inlined/splash.video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"evenodd\">\n    <path d=\"M0 0h368v207H0z\" opacity=\".197\"/>\n    <path fill-rule=\"nonzero\" d=\"M156 103.42c-.05-15.271 12.782-27.817 28.26-27.41 14.299.384 26.8 12.183 26.74 27.614-.056 15.175-12.414 27.432-27.602 27.376-15.187-.057-27.454-12.404-27.398-27.58zm41.244.875a7.159 7.159 0 00-1.266-1.3 2004.079 2004.079 0 00-16.795-9.451c-1.4-.787-2.516-.236-2.516 1.3-.056 6.329-.056 12.658 0 18.986 0 1.547 1.116 2.141 2.51 1.354 5.662-3.14 11.301-6.314 16.918-9.525.413-.251.658-.797 1.155-1.364h-.006z\"/>\n  </g>\n",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 368 207"
    }
  };
});