define("ember-svg-jar/inlined/carrot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M52.888 30.15c2.468-8.386 5.51-18.547 5.51-21.773C58.397 3.75 54.643 0 50.013 0c-4.63 0-8.383 3.751-8.383 8.377 0 3.236 3.095 13.399 5.56 21.773\"/>\n      <path d=\"M58.55 8.648a8.375 8.375 0 017.432-.685A8.376 8.376 0 0170.773 18.8c-.917 2.366-11.786 11.62-11.786 11.62H41.041S30.172 21.166 29.255 18.8a8.376 8.376 0 014.792-10.837 8.376 8.376 0 017.431.685\"/>\n    </g>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M50.014 27.499c-9.898 0-17.952 8.053-17.952 17.953 0 5.432 1.815 18.585 4.748 30.362 1.637 6.574 3.393 11.858 5.22 15.704 2.467 5.194 5.079 7.719 7.984 7.719 2.906 0 5.517-2.525 7.984-7.719 1.827-3.846 3.583-9.13 5.22-15.704 2.933-11.777 4.748-24.93 4.748-30.362 0-9.9-8.053-17.953-17.952-17.953zm9.443 47.379c-4.026 16.168-7.83 20.482-9.443 20.482-1.429 0-4.579-3.389-8.079-15.41h6a1.938 1.938 0 100-3.877h-7.06c-.101-.392-.202-.789-.303-1.195-2.862-11.493-4.633-24.223-4.633-29.426 0-7.762 6.314-14.077 14.076-14.077 7.76 0 14.075 6.315 14.075 14.077 0 5.203-1.772 17.933-4.633 29.426z\"/>\n      <path d=\"M49.903 45.35c0-1.07-.868-1.938-1.938-1.938h-6.858a1.938 1.938 0 100 3.876h6.858c1.07 0 1.938-.867 1.938-1.938zM58.229 57.565H47.255a1.938 1.938 0 100 3.876H58.23a1.938 1.938 0 100-3.876z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});