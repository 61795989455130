define("ember-svg-jar/inlined/icon.question.breaks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(9 9)\">\n    <circle cx=\"36.825\" cy=\"43.175\" r=\"29.675\" fill=\"#DDD\"/>\n    <g fill=\"#A7A8AA\" fill-rule=\"nonzero\">\n      <path d=\"M40.574.151a40.8 40.8 0 00-27.045 10.235C6.129 16.928 1.35 25.898.074 35.643a1.925 1.925 0 103.817.5C6.292 17.82 22.062 4.002 40.574 4.002 60.975 4.001 77.573 20.6 77.573 41c0 20.401-16.598 36.999-36.999 36.999-14.445 0-27.661-8.493-33.669-21.636a1.925 1.925 0 00-3.501 1.6A40.906 40.906 0 0018.212 75.19a40.686 40.686 0 0022.362 6.66c22.524 0 40.849-18.325 40.849-40.849C81.423 18.476 63.098.151 40.574.151z\"/>\n      <path d=\"M19.782 58.048a1.925 1.925 0 10-2.975 2.443c5.867 7.147 14.53 11.246 23.767 11.246 16.948 0 30.737-13.789 30.737-30.737S57.522 10.263 40.574 10.263 9.837 24.052 9.837 41c0 3.248.505 6.448 1.5 9.51a1.925 1.925 0 003.661-1.19 26.87 26.87 0 01-1.31-8.32c0-14.826 12.06-26.887 26.886-26.887C55.4 14.113 67.461 26.174 67.461 41c0 14.825-12.061 26.887-26.887 26.887a26.81 26.81 0 01-20.792-9.839z\"/>\n      <path d=\"M40.574 24.22a1.925 1.925 0 00-1.925 1.926v14.896c0 1.062.862 1.924 1.925 1.924h12.889a1.925 1.925 0 000-3.85H42.499v-12.97a1.925 1.925 0 00-1.925-1.925z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});