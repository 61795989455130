define("ember-svg-jar/inlined/challenge.bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n      <circle cx=\"117.973\" cy=\"59.78\" r=\"59.78\" fill=\"#0A6C74\"/>\n      <circle cx=\"40.707\" cy=\"218.717\" r=\"18.707\" fill=\"#0A6C74\"/>\n      <circle cx=\"307.073\" cy=\"144.773\" r=\"37.82\" fill=\"#0A6C74\"/>\n  </g>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 366 228"
    }
  };
});