define("ember-svg-jar/inlined/weights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M37.968 44.558h24.016v10.884H37.968zM13.348 44.558H5.442a5.442 5.442 0 100 10.884h7.906V44.558zM94.51 44.558h-7.906v10.884h7.905a5.442 5.442 0 100-10.884z\"/>\n    </g>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M31.817 29.66c-3.138 0-5.864 1.793-7.211 4.408a8.07 8.07 0 00-5.102-1.812c-4.471 0-8.109 3.637-8.109 8.108v19.272c0 4.471 3.638 8.109 8.109 8.109a8.07 8.07 0 005.102-1.812 8.115 8.115 0 007.21 4.407c4.472 0 8.11-3.638 8.11-8.109V37.77c0-4.471-3.638-8.109-8.11-8.109zM19.504 63.84a4.209 4.209 0 01-4.204-4.204V40.364a4.209 4.209 0 014.204-4.204 4.209 4.209 0 014.204 4.204v19.272a4.208 4.208 0 01-4.204 4.204zM36.02 62.23a4.209 4.209 0 01-4.203 4.204 4.209 4.209 0 01-4.204-4.204V37.77a4.209 4.209 0 014.204-4.204 4.209 4.209 0 014.203 4.204v24.462zM80.458 32.256a8.07 8.07 0 00-5.102 1.812 8.115 8.115 0 00-7.21-4.408c-4.472 0-8.11 3.638-8.11 8.109V62.23c0 4.471 3.638 8.109 8.11 8.109 3.137 0 5.863-1.794 7.21-4.408a8.07 8.07 0 005.102 1.812c4.471 0 8.109-3.637 8.109-8.108V40.364c0-4.47-3.638-8.108-8.109-8.108zM72.349 62.23a4.209 4.209 0 01-4.204 4.204 4.209 4.209 0 01-4.204-4.204V37.77a4.209 4.209 0 014.204-4.204 4.209 4.209 0 014.204 4.204v24.462zm12.313-2.595a4.209 4.209 0 01-4.204 4.204 4.209 4.209 0 01-4.204-4.204V40.364a4.209 4.209 0 014.204-4.204 4.209 4.209 0 014.204 4.204v19.272z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});