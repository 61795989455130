define("ember-svg-jar/inlined/prawns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M5.168 36.3908863h64.4454599s25.461393.3520741 25.461393 28.1025797v3.3806685s.8452302 23.7179321-23.7181845 23.7179321H55.8792891s11.3307288-1.690208 11.3307288-11.3307287v-3.3014203s.4224889-11.4761012-11.4761013-11.4761012H30.9461335S5.168 66.183674 5.168 36.3908863z\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M5.168 28.3908863h64.4454599s25.461393.3520741 25.461393 28.1025797v3.3806685s.8452302 23.7179321-23.7181845 23.7179321H55.8792891s11.3307288-1.690208 11.3307288-11.3307287v-3.3014203s.4224889-11.4761012-11.4761013-11.4761012H30.9461335S5.168 58.183674 5.168 28.3908863z\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M44.4223437 73.4557146H32.7925413l9.7195165 9.7197689L32.7925413 92.895h11.6298024c5.3679312 0 9.7195166-4.3515854 9.7195166-9.7195165v-.0002524c0-5.3679312-4.3515854-9.7195165-9.7195166-9.7195165zM32.637 28.602v28.103M57.781 28.602v28.103M85.672 34.941L63.697 59.24M93.913 64.311H66.867\"/>\n    <path fill=\"currentColor\" d=\"M19.2158322 36.8427594c0 1.5736072-1.275543 2.8491502-2.8491502 2.8491502-1.57335476 0-2.84915014-1.275543-2.84915014-2.8491502 0-1.5736071 1.27579538-2.8491501 2.84915014-2.8491501 1.5736072 0 2.8491502 1.275543 2.8491502 2.8491501\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M5.59058987 27.7569763s-1.69046043-12.04396276 9.29702761-12.04396276H57.7808426\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M5.59058987 27.9682712S3.90012944 7.895 14.88761748 7.895H57.7808426\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});