define("ember-intl/helpers/-format-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @private
   * @hide
   */
  const AbstractHelper = Ember.Helper.extend({
    intl: null,
    init() {
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }
      this._super();
      this.intl = Ember.getOwner(this).lookup('service:intl');
      this.intl.on('localeChanged', this, 'recompute');
    },
    format() {
      throw new Error('not implemented');
    },
    compute([value], options) {
      if (Ember.isEmpty(value)) {
        if (options.allowEmpty ?? this.allowEmpty) {
          return;
        }
        if (typeof value === 'undefined') {
          throw new Error(`${this} helper requires value attribute.`);
        }
      }
      return this.format(value, options);
    },
    willDestroy() {
      this._super();
      this.intl.off('localeChanged', this, 'recompute');
    }
  });
  var _default = _exports.default = AbstractHelper;
});