define("ember-svg-jar/inlined/shape.pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <g id=\"shape.tag\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n        <polygon id=\"tag\" fill=\"currentColor\" points=\"1.10520859e-16 0 16 0 3.99804688 11.9296875 16 24 0 24\"/>\n    </g>\n",
    "attrs": {
      "viewBox": "0 0 16 24",
      "fill": "currentColor"
    }
  };
});