define("ember-svg-jar/inlined/icon.question.coffee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path stroke=\"#A7A8AA\" stroke-width=\"4\" d=\"M47 22h40.739v46a9.969 9.969 0 01-2.929 7.071A9.969 9.969 0 0177.74 78h0-20.738a9.969 9.969 0 01-7.071-2.929A9.969 9.969 0 0147 68h0V22z\"/>\n    <path fill=\"#DDD\" d=\"M53.117 35.395h28.506v33.947H53.117z\"/>\n    <path stroke=\"#A7A8AA\" stroke-width=\"4\" d=\"M88.771 31.245a12.236 12.236 0 016.642 3.412A12.151 12.151 0 0199 43.29a12.15 12.15 0 01-3.491 8.536 12.24 12.24 0 01-6.472 3.462h0z\"/>\n    <g>\n      <path stroke=\"#A7A8AA\" stroke-width=\"4\" d=\"M28.935 29.999c.014.217.021.437.021.658h0V58a9.969 9.969 0 01-2.929 7.071A9.969 9.969 0 0118.957 68h0H11a9.969 9.969 0 01-7.071-2.929A9.969 9.969 0 011 58h0V30.657c0-.221.007-.44.021-.658h0z\"/>\n      <path fill=\"#DDD\" d=\"M4.797 37.206h20.362v23.763H4.797z\"/>\n      <path stroke=\"#A7A8AA\" stroke-width=\"4\" d=\"M30.851 36.602a8.23 8.23 0 013.77 2.097C36.087 40.133 37 42.113 37 44.303c0 2.15-.879 4.097-2.3 5.524a8.224 8.224 0 01-3.633 2.12h0z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});