define("ember-svg-jar/inlined/no-smoking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M98.03 42.048H1.953A1.953 1.953 0 000 44.001v13.56c0 1.079.874 1.953 1.953 1.953H98.03a1.953 1.953 0 001.952-1.952V44a1.953 1.953 0 00-1.952-1.953zm-13.898 3.906v9.655h-60.27v-9.655h60.27zm-80.226 0h16.05v9.655H3.906v-9.655zm92.17 9.655h-8.039v-9.655h8.04v9.655zM61.458 36.62h31.039c4.127 0 7.485-3.358 7.485-7.485A7.446 7.446 0 0096.796 23a11.87 11.87 0 00.17-2.013c0-6.507-5.293-11.801-11.8-11.801a11.8 11.8 0 00-10.734 6.891 8.92 8.92 0 00-4.448-1.18 8.906 8.906 0 00-8.678 6.752c-4.058.08-7.334 3.407-7.334 7.484 0 4.128 3.358 7.486 7.486 7.486zm0-11.066h1.538a1.953 1.953 0 001.951-1.886 5.018 5.018 0 015.037-4.864c1.534 0 2.966.687 3.929 1.883a1.952 1.952 0 003.45-.925c.592-3.802 3.946-6.67 7.802-6.67 4.354 0 7.896 3.542 7.896 7.896 0 .824-.125 1.635-.374 2.41a1.953 1.953 0 001.136 2.41 3.565 3.565 0 012.254 3.327 3.584 3.584 0 01-3.58 3.58h-31.04a3.584 3.584 0 01-3.58-3.58 3.584 3.584 0 013.58-3.58z\"/>\n    </g>\n    <g transform=\"translate(31.44 50.594)\">\n      <circle cx=\"19.486\" cy=\"19.626\" r=\"19.439\" fill=\"currentColor\"/>\n      <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M22.248 19.686l3.452-3.452a1.953 1.953 0 00-2.762-2.761l-3.452 3.452-3.452-3.452a1.953 1.953 0 00-2.762 2.761l3.452 3.452-3.452 3.452a1.953 1.953 0 002.762 2.762l3.452-3.452 3.452 3.452c.381.381.88.572 1.38.572a1.953 1.953 0 001.38-3.334l-3.45-3.452z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});