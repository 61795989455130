define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M56.803 21.848c-3.987 0-7.115 3.318-7.115 3.318s-2.95-3.318-7.116-3.318c-4.052 0-7.361 3.734-7.578 8.255-.122 2.553.615 4.497 1.654 6.257C38.722 39.877 49.7 47.01 49.7 47.01s10.938-7.102 13.027-10.65c1.04-1.768 1.775-3.704 1.653-6.257-.217-4.521-3.525-8.255-7.578-8.255\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M68.547 58.73h-7.761l-1.555-3.124a1.953 1.953 0 00-3.566.155l-3.718 9.447-3.397-11.587a1.953 1.953 0 00-3.696-.157l-2.883 7.437-1.184-1.774a1.953 1.953 0 00-1.624-.869h-8.334a1.953 1.953 0 100 3.906h7.289l2.692 4.032a1.955 1.955 0 003.445-.378l2.163-5.581 3.384 11.541a1.953 1.953 0 003.691.166l4.197-10.665.137.274a1.953 1.953 0 001.748 1.083h8.971a1.953 1.953 0 000-3.906z\"/>\n      <path d=\"M72.064 0H27.311c-3.998 0-7.25 3.253-7.25 7.25v85.5c0 3.997 3.252 7.25 7.25 7.25h44.753c3.998 0 7.25-3.253 7.25-7.25V7.25c0-3.997-3.252-7.25-7.25-7.25zM23.967 7.25c0-1.844 1.5-3.344 3.344-3.344h44.753c1.844 0 3.344 1.5 3.344 3.344v73.505H23.967V7.25zm51.441 85.5c0 1.844-1.5 3.344-3.344 3.344H27.311a3.348 3.348 0 01-3.344-3.344v-8.088h51.441v8.088z\"/>\n      <path d=\"M47.793 11.79h3.79a1.953 1.953 0 100-3.907h-3.79a1.953 1.953 0 000 3.906zM47.793 92.33h3.79a1.953 1.953 0 100-3.905h-3.79a1.953 1.953 0 000 3.906z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});