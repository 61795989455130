define("ember-svg-jar/inlined/icon.question.mood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"#DDD\" d=\"M82.902 13c-6.233 0-11.123 5.187-11.123 5.187S67.168 13 60.655 13c-6.335 0-11.507 5.836-11.846 12.903-.19 3.992.961 7.03 2.585 9.782 3.243 5.498 20.405 16.65 20.405 16.65s17.1-11.104 20.364-16.65c1.627-2.764 2.775-5.79 2.584-9.782C94.41 18.836 89.237 13 82.902 13\"/>\n    <path fill=\"#A7A8AA\" d=\"M98.41 61.442c-2.49-3.418-7.215-4.324-10.755-2.062l-11.019 7.04c0-.98-.114-2.07-.445-3.158-.7-2.303-2.933-6.167-10.016-6.167h-5.577c-4.016 0-7.967-.927-11.743-2.756-7.243-3.509-13.786-4.068-17.95-1.535-3.665 2.23-6.49 5.007-7.684 6.27h-1.93v-.433a1.953 1.953 0 00-1.953-1.953H1.953A1.953 1.953 0 000 58.64v21.215c0 1.078.874 1.953 1.953 1.953H19.34a1.953 1.953 0 001.953-1.953v-3.257c4.086-.93 10.732-1.549 12.158 1.294 1.55 3.091 5.512 5.421 11.776 6.925 4.554 1.094 10.19 1.665 15.83 1.665 9.272 0 18.557-1.544 23.07-4.847l12.33-8.503c1.83-1.262 3.045-3.178 3.422-5.395a8.253 8.253 0 00-1.468-6.296zm-81.024 16.46H3.906V60.595h13.48v17.309zm78.642-10.82a4.278 4.278 0 01-1.79 2.835l-12.354 8.52a1.724 1.724 0 00-.049.035c-5.641 4.154-23.657 5.44-35.697 2.548-4.877-1.17-8.23-2.949-9.196-4.878-1.668-3.325-5.561-4.236-9.328-4.236-2.346 0-4.643.353-6.323.697v-9.622h2.79c.568 0 1.107-.248 1.479-.677.03-.036 3.092-3.556 7.375-6.163 2.965-1.804 8.28-1.163 14.217 1.713 4.311 2.089 8.835 3.148 13.446 3.148h5.576c2.864 0 4.801.756 5.759 2.248 1.037 1.617.864 3.933.574 5.288H56.054a1.953 1.953 0 000 3.906h17.84c.684-.007 1.035-.297 1.05-.307l14.814-9.465c1.834-1.172 4.196-.712 5.495 1.07.702.964.977 2.15.775 3.34z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});