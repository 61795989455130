define("ember-stopwatch/services/clock", ["exports", "ember-stopwatch/utils/clock"], function (_exports, _clock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ClockService = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class ClockService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "clock", _descriptor, this);
      _initializerDefineProperty(this, "second", _descriptor2, this);
      _initializerDefineProperty(this, "minute", _descriptor3, this);
      _initializerDefineProperty(this, "hour", _descriptor4, this);
      _initializerDefineProperty(this, "day", _descriptor5, this);
      this.clock.start();
      this.clock.on('tick', this, this.updateTrackedDivisions);
      this.updateTrackedDivisions();
    }
    get time() {
      return this.clock.time;
    }
    get date() {
      return this.clock.date;
    }
    on(event, target, method) {
      return this.clock.on(event, target, method);
    }
    off(event, target, method) {
      return this.clock.off(event, target, method);
    }
    has(name) {
      return this.clock.has(name);
    }

    /**
     * The usage of `@tracked` and the guards in place are intentional
     * optomizations meant to prevent litening properties from recomputing.
     * I believe old version of Ember used to optimize for this and prevent
     * listeners from recomputing if the value was the same. This is useful
     * for guarding against excessive template helper calls as a clock is
     * always yielding new time values on every tick.
     */
    updateTrackedDivisions() {
      this.second = this.clock.second;
      if (this.minute !== this.clock.minute) {
        this.minute = this.clock.minute;
      }
      if (this.hour !== this.clock.hour) {
        this.hour = this.clock.hour;
      }
      if (this.day !== this.clock.day) {
        this.day = this.clock.day;
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.clock.stop(true);
      this.clock.off('tick', this, this.updateTrackedDivisions);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clock", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _clock.default();
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "second", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "minute", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hour", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "day", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});