define("ember-stopwatch/utils/timer", ["exports", "ember-stopwatch/utils/stopwatch"], function (_exports, _stopwatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_RESOLUTION_MILLIS = void 0;
  // for now, aliasing the stopwatch default millis
  const DEFAULT_RESOLUTION_MILLIS = _exports.DEFAULT_RESOLUTION_MILLIS = _stopwatch.DEFAULT_TICK_MILLIS;

  /**
   * In addition to the events emitted by Stopwatch, the Timer also emits: expired, restart, pause
   */
  class Timer extends _stopwatch.default {
    constructor(expirationMillis, tickMillis = DEFAULT_RESOLUTION_MILLIS) {
      super(Math.min(expirationMillis, tickMillis));
      this.expirationMillis = expirationMillis;
      this.on('tick', this, this._expirationHandler);
    }
    start() {
      if (this.isExpired) {
        this.reset(true);
      }
      super.start();
    }
    restart() {
      this.reset(true);
      this.start();
      this.trigger('restart', this);
    }

    // this is a domain convenience that just calls stop()
    pause(force = false) {
      this.stop(force);
      this.trigger('pause', this);
    }
    get remainingMillis() {
      return this.expirationMillis - this.elapsedMillis;
    }
    get isExpired() {
      return this.remainingMillis <= 0;
    }
    _expirationHandler() {
      if (this.isExpired) {
        this.stop(true);
        this.trigger('expired', this);
      }
    }
  }
  _exports.default = Timer;
});