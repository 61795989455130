define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4FTp7ZWN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"item\",\"model\"],[[30,[36,1],[\"ember-sortable@sortable-item\"],[[\"direction\",\"registerItem\",\"deregisterItem\",\"setSelectedItem\",\"update\",\"prepare\",\"commit\",\"activateKeyDown\",\"deactivateKeyDown\"],[[32,0,[\"direction\"]],[30,[36,0],[[32,0],\"registerItem\"],null],[30,[36,0],[[32,0],\"deregisterItem\"],null],[30,[36,0],[[32,0],\"setSelectedItem\"],null],[30,[36,0],[[32,0],\"update\"],null],[30,[36,0],[[32,0],\"prepare\"],null],[30,[36,0],[[32,0],\"commit\"],null],[30,[36,0],[[32,0],\"activateKeyDown\"],null],[30,[36,0],[[32,0],\"deactivateKeyDown\"],null]]]],[32,0,[\"model\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-sortable/templates/components/sortable-group.hbs"
    }
  });
});