define("ember-svg-jar/inlined/icon.question.sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"#DDD\">\n      <path d=\"M19.045 43.375h25.162v16.93H19.045zM55.794 43.375h25.161v16.93H55.794z\"/>\n      <g>\n        <path d=\"M75.802 87.862h14.923v8.689H75.802zM9.275 87.862h14.923v8.689H9.275z\"/>\n      </g>\n    </g>\n    <path fill=\"#A7A8AA\" d=\"M98.047 75.536v-8.104c0-3.55-2.05-6.63-5.026-8.121V42.306c0-5.75-4.678-10.428-10.429-10.428H17.408c-5.75 0-10.429 4.678-10.429 10.428V59.31c-2.976 1.492-5.026 4.57-5.026 8.12v8.105A1.953 1.953 0 000 77.489v10.372c0 1.079.874 1.953 1.953 1.953h96.094A1.953 1.953 0 00100 87.861V77.49a1.953 1.953 0 00-1.953-1.953zM10.886 58.353V42.306a6.53 6.53 0 016.522-6.522h65.184a6.53 6.53 0 016.523 6.522v16.047h-78.23zm-5.027 9.079a5.178 5.178 0 015.173-5.173h77.936a5.179 5.179 0 015.173 5.173v8.104H5.859v-8.104zm90.235 18.476H3.906v-6.466h92.188v6.466zM36.586 26.17c.342.63 1 1.022 1.717 1.022h7.45a1.953 1.953 0 000-3.907h-3.846l5.309-8.147a1.953 1.953 0 00-1.637-3.02h-7.047a1.953 1.953 0 000 3.907h3.443l-5.308 8.147a1.953 1.953 0 00-.08 1.997zM49.082 21.007a1.953 1.953 0 001.73 1.045h10.885a1.953 1.953 0 000-3.906H54.53l8.334-12.084A1.954 1.954 0 0061.257 3h-10.17a1.953 1.953 0 000 3.906h6.45L49.205 18.99a1.953 1.953 0 00-.122 2.017z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});