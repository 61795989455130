define("ember-stopwatch/utils/evented", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Partial wrapper for Ember's Evented Mixin, enabling
   * a pure class-based derivation
   */
  class _default {
    constructor() {
      _defineProperty(this, "eventManager", Ember.Object.extend(Ember.Evented).create());
    }
    /**
     * Subscribes to events of the given name
     * @param name the name of the event
     * @param target optional target for this
     * @param method the method to call
     * @return {Stopwatch}
     */
    on(name, target, method) {
      this.eventManager.on(name, target, method);
      return this;
    }
    off(name, target, method) {
      this.eventManager.off(name, target, method);
      return this;
    }

    /**
     * Checks if subscriptions exist for the given name
     * @param name
     * @return {Promise<boolean> | boolean}
     */
    has(name) {
      return this.eventManager.has(name);
    }
    trigger(name, ...args) {
      this.eventManager.trigger(name, ...args);
    }
  }
  _exports.default = _default;
});