define("ember-stopwatch/utils/clock", ["exports", "ember-stopwatch/utils/stopwatch"], function (_exports, _stopwatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SECOND_RESOLUTION = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const SECOND_RESOLUTION = _exports.SECOND_RESOLUTION = 1000;
  let Clock = _exports.default = (_dec = Ember._tracked, (_class = class Clock extends _stopwatch.default {
    constructor() {
      super(SECOND_RESOLUTION);
      _initializerDefineProperty(this, "date", _descriptor, this);
      this.on('tick', this, this._tickHandler);
      this.on('start', this, this._tickHandler);
    }
    get time() {
      return this.date ? this.date.getTime() : undefined;
    }
    get second() {
      return this.date ? this.date.getSeconds() : undefined;
    }
    get minute() {
      return this.date ? this.date.getMinutes() : undefined;
    }
    get hour() {
      return this.date ? this.date.getHours() : undefined;
    }
    get day() {
      return this.date ? this.date.getDate() : undefined;
    }
    start() {
      if (!this.date) {
        // start on a discrete tick
        this.date = new Date();
        setTimeout(() => {
          super.start();
        }, this.tickMillis - Date.now() % this.tickMillis);
      }
    }
    _tickHandler() {
      const prevDate = this.date;
      this.date = new Date();
      this.trigger('second', this);
      if (prevDate.getMinutes() !== this.date.getMinutes()) {
        this.trigger('minute', this);
        if (prevDate.getHours() !== this.date.getHours()) {
          this.trigger('hour', this);
          if (prevDate.getDate() !== this.date.getDate()) {
            this.trigger('day', this);
          }
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});