define("ember-svg-jar/inlined/grad.tommy.bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\"6.527%\" x2=\"50%\" y2=\"89.189%\" id=\"bed-a\">\n      <stop stop-color=\"#24BBBC\" offset=\"0%\"/>\n      <stop stop-color=\"#BA7EE2\" offset=\"100%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\">\n    <path d=\"M12.651 7.052h13.936v10.47H12.651zm14.46 27.574h8.264V40h-8.264zm-22.487 0h8.265V40H4.624z\" fill=\"currentColor\"/>\n    <path d=\"M38.918 27.003V21.99c0-2.196-1.135-4.1-2.783-5.023V6.45c0-3.557-2.591-6.45-5.776-6.45H9.64C6.456 0 3.865 2.893 3.865 6.45v10.517c-1.648.923-2.783 2.827-2.783 5.023v5.012C.484 27.002 0 27.543 0 28.21v6.416c0 .667.484 1.208 1.082 1.208h37.836c.598 0 1.082-.541 1.082-1.208V28.21c0-.667-.484-1.207-1.082-1.207ZM6.028 16.375V6.45c0-2.224 1.621-4.034 3.613-4.034H30.36c1.992 0 3.612 1.81 3.612 4.034v9.925H6.03ZM3.246 21.99c0-1.764 1.285-3.2 2.865-3.2h27.78c1.58 0 2.865 1.436 2.865 3.2v5.012H3.245V21.99Zm34.592 11.428H2.163v-4h35.674v4Z\" fill=\"url(#bed-a)\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});