define("ember-math-helpers/helpers/log1p", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.log1p = log1p;
  /**
   * Executes `Math.log1p` on the number passed to the helper.
   *
   * ```hbs
   * {{log1p a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.log1p`
   * @return {number} The log1p of the passed number
   */
  function log1p([number]) {
    return Math.log1p(number);
  }
  var _default = _exports.default = Ember.Helper.helper(log1p);
});