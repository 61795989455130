define("ember-svg-jar/inlined/salmon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <ellipse cx=\"32.768\" cy=\"49.302\" fill=\"currentColor\" fill-opacity=\"0.5\" rx=\"15.138\" ry=\"29.802\"/>\n    <ellipse cx=\"25.161\" cy=\"49.302\" stroke=\"currentColor\" stroke-width=\"4\" rx=\"13.138\" ry=\"27.802\"/>\n    <ellipse cx=\"25.161\" cy=\"49.302\" stroke=\"currentColor\" stroke-width=\"4\" rx=\"5.965\" ry=\"17.557\"/>\n    <ellipse cx=\"25.161\" cy=\"49.433\" stroke=\"currentColor\" stroke-width=\"4\" rx=\"1\" ry=\"4.947\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M23.8414383 21.301899c14.7653605-2.0599315 27.808164 1.7698262 39.1284103 11.489273 11.3202464 9.7194468 19.5353546 8.0528895 24.6453247-4.9996719 1.5894572 14.9325104-2.6187133 22.1373545-12.6245117 21.6145325\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M23.8414383 77.5630894c14.7653605 2.0599314 27.808164-1.7698263 39.1284103-11.4892731 11.3202464-9.7194468 19.5353546-8.0528895 24.6453247 4.9996719 1.5894572-14.9325104-2.6187133-22.1373545-12.6245117-21.6145324\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});