define("ember-svg-jar/inlined/splash.image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"#000\" fill-rule=\"nonzero\">\n    <path d=\"M0 0h368v207H0z\" opacity=\".197\"/>\n    <path d=\"M212 121.667h-55L175.333 85l13.75 27.5 9.167-18.333z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 368 207"
    }
  };
});