define("ember-svg-jar/inlined/icon.question.alcohol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"#A7A8AA\" d=\"M83.932 26.775c0-7.717-6.277-13.994-13.993-13.994-7.054 0-12.904 5.246-13.857 12.04H41.407V5.943c0-.794-.48-1.509-1.215-1.809L30.418.145a1.953 1.953 0 00-1.476 3.617L37.5 7.256v17.566H17.953A1.953 1.953 0 0016 26.775v61.463C16 94.723 21.277 100 27.762 100H60.13c6.485 0 11.762-5.277 11.762-11.762V40.632c6.795-.954 12.04-6.804 12.04-13.857zM67.986 88.238c0 4.332-3.525 7.856-7.856 7.856H27.762c-4.332 0-7.856-3.524-7.856-7.856v-59.51H37.5v23.188a1.953 1.953 0 003.907 0V28.728h26.579v59.51zm3.906-51.565v-9.898a1.953 1.953 0 00-1.953-1.954H60.04c.912-4.63 5.004-8.134 9.898-8.134 5.562 0 10.087 4.525 10.087 10.088 0 4.894-3.503 8.985-8.134 9.898z\"/>\n    <path fill=\"#DDD\" d=\"M60.954 46.75v42.313H26.937V46.75z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});