define("ember-svg-jar/inlined/drink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M87.13 26.043c0-7.794-6.34-14.134-14.133-14.134-7.124 0-13.033 5.298-13.996 12.16H44.179V5.003c0-.801-.485-1.523-1.227-1.826L33.08-.854A1.973 1.973 0 0031.59 2.8l8.644 3.528V24.07H20.491c-1.09 0-1.973.883-1.973 1.973V88.12c0 6.55 5.33 11.88 11.88 11.88H63.09c6.55 0 11.88-5.33 11.88-11.88V40.038c6.862-.963 12.16-6.871 12.16-13.995zM71.024 88.12c0 4.375-3.56 7.934-7.934 7.934H30.398c-4.375 0-7.934-3.56-7.934-7.934V28.015h17.77v23.42a1.973 1.973 0 003.945 0v-23.42h26.845v60.106zM74.97 36.04v-9.997c0-1.09-.883-1.973-1.972-1.973H63c.921-4.677 5.054-8.216 9.997-8.216 5.618 0 10.188 4.57 10.188 10.189 0 4.943-3.539 9.075-8.216 9.997z\"/>\n    <path fill=\"currentColor\" d=\"M63.922 46.218v42.736H29.565V46.218z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});