define("ember-svg-jar/inlined/nav.think", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"evenodd\">\n    <path d=\"M23.809 23.074a7.702 7.702 0 00-2.074 5.266v.672h-8.412v-.67c0-1.94-.752-3.827-2.117-5.315a8.534 8.534 0 01-2.25-6.092c.154-4.554 3.989-8.269 8.55-8.281h.023c2.287 0 4.438.889 6.058 2.505a8.52 8.52 0 012.52 6.073c0 2.176-.816 4.25-2.298 5.842zM21.73 30.52a4.209 4.209 0 01-4.204 4.204 4.209 4.209 0 01-4.204-4.204v-.231h8.408v.23zm2.757-20.264a9.787 9.787 0 00-6.959-2.877h-.026c-5.24.014-9.646 4.282-9.822 9.514a9.802 9.802 0 002.585 6.998c1.148 1.251 1.78 2.832 1.78 4.451v2.178a5.486 5.486 0 005.481 5.48 5.486 5.486 0 005.48-5.48v-.83c.001-.014.004-.026.004-.04v-1.31a6.43 6.43 0 011.731-4.395 9.818 9.818 0 002.641-6.712 9.788 9.788 0 00-2.895-6.977zM17.527 4.84c.132 0 .24-.106.24-.239V.58a.24.24 0 10-.48 0V4.6c0 .133.108.24.24.24M29.582 5.474L26.74 8.32a.238.238 0 00.169.408.237.237 0 00.169-.07l2.844-2.844a.24.24 0 00-.339-.339M7.977 27.08l-2.843 2.843a.238.238 0 00.169.409.237.237 0 00.169-.07l2.844-2.844a.239.239 0 10-.339-.338M34.815 17.629h-4.021a.239.239 0 100 .478h4.021a.239.239 0 100-.478M4.26 17.629H.24a.239.239 0 100 .478H4.26a.239.239 0 100-.478M27.077 27.08a.24.24 0 00-.338.338l2.843 2.843a.237.237 0 00.339 0 .239.239 0 000-.338l-2.844-2.843zM7.977 8.656a.237.237 0 00.339 0 .239.239 0 000-.338L5.472 5.474a.24.24 0 00-.338.339l2.843 2.843z\"/>\n    <path d=\"M16.988 10.167c-3.406.253-6.226 3.003-6.56 6.398a7.157 7.157 0 001.921 5.63 7.777 7.777 0 012.153 5.367.24.24 0 00.479 0 8.25 8.25 0 00-2.285-5.695 6.681 6.681 0 01-1.792-5.256c.312-3.166 2.942-5.73 6.119-5.967a.238.238 0 00.22-.256.24.24 0 00-.255-.22\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});