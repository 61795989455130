define("ember-svg-jar/inlined/target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M40.997 19.763C24.298 19.763 10.76 33.301 10.76 50c0 16.7 13.538 30.237 30.237 30.237 16.7 0 30.237-13.538 30.237-30.237 0-16.7-13.537-30.237-30.237-30.237zm0 47.856c-9.73 0-17.619-7.888-17.619-17.619 0-9.73 7.888-17.62 17.62-17.62 9.73 0 17.618 7.89 17.618 17.62s-7.888 17.62-17.619 17.62z\"/>\n    <g fill=\"currentColor\">\n      <path d=\"M99.234 68.081a1.943 1.943 0 00-1.689-.982h-5.638V39.012l7.104-8.163a1.943 1.943 0 00-2.932-2.551l-4.171 4.794V27.63l7.103-8.163a1.943 1.943 0 00-2.931-2.55l-4.172 4.793v-3.518a1.944 1.944 0 00-3.886 0v3.518l-4.172-4.794a1.943 1.943 0 10-2.932 2.551l7.104 8.163v5.46l-4.172-4.793a1.943 1.943 0 10-2.932 2.551l7.104 8.163V67.1h-5.638a1.943 1.943 0 00-1.67 2.935l7.58 12.765a1.943 1.943 0 003.342 0l7.58-12.765c.356-.6.363-1.347.018-1.954zm-9.27 9.92l-4.166-7.015h8.333l-4.167 7.016zM40.997 30.438c-10.787 0-19.562 8.775-19.562 19.562s8.775 19.562 19.562 19.562S60.56 60.787 60.56 50s-8.776-19.562-19.563-19.562zm0 35.238c-8.644 0-15.676-7.032-15.676-15.676s7.032-15.676 15.676-15.676S56.673 41.356 56.673 50s-7.032 15.676-15.676 15.676z\"/>\n      <path d=\"M40.997 9.003C18.391 9.003 0 27.394 0 50s18.391 40.997 40.997 40.997S81.995 72.606 81.995 50 63.603 9.003 40.997 9.003zm0 78.108c-20.463 0-37.11-16.648-37.11-37.111 0-20.463 16.647-37.111 37.11-37.111S78.108 29.537 78.108 50c0 20.463-16.648 37.111-37.11 37.111z\"/>\n      <path d=\"M40.997 42.274c-4.26 0-7.726 3.466-7.726 7.726s3.466 7.726 7.726 7.726S48.723 54.26 48.723 50s-3.466-7.726-7.726-7.726zm0 11.566a3.844 3.844 0 01-3.84-3.84 3.844 3.844 0 013.84-3.84 3.844 3.844 0 013.84 3.84 3.844 3.844 0 01-3.84 3.84z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});