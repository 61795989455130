define("ember-svg-jar/inlined/rugby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M49.922 98.047C59.306 92.882 67.098 85.39 72.427 76.4h-45.01c5.33 8.99 13.12 16.482 22.505 21.647zM49.922 1.953C40.538 7.118 32.746 14.61 27.417 23.6h45.01c-5.33-8.99-13.12-16.482-22.505-21.647z\"/>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M73.485 20.644C68.077 12.391 60.466 5.527 50.864.242a1.954 1.954 0 00-1.884 0c-9.602 5.285-17.213 12.15-22.62 20.402-5.755 8.782-8.673 18.659-8.673 29.356 0 10.697 2.918 20.574 8.672 29.356 5.408 8.253 13.019 15.117 22.621 20.402a1.953 1.953 0 001.884 0c9.602-5.285 17.213-12.15 22.62-20.402C79.24 70.574 82.157 60.697 82.157 50c0-10.697-2.917-20.574-8.671-29.356zM49.922 95.808C31.904 85.523 21.594 68.882 21.594 50s10.31-35.523 28.328-45.808C67.94 14.477 78.25 31.118 78.25 50S67.94 85.523 49.922 95.808z\"/>\n    <path fill=\"#A7A8AA\" d=\"M55.03 42.728a1.953 1.953 0 000-3.906h-3.155v-3.605a1.953 1.953 0 00-3.906 0v3.605h-3.155a1.953 1.953 0 100 3.906h3.155v5.319h-3.155a1.953 1.953 0 100 3.906h3.155v5.319h-3.155a1.953 1.953 0 000 3.906h3.155v5.47a1.953 1.953 0 003.906 0v-5.47h3.154a1.953 1.953 0 000-3.906h-3.154v-5.319h3.154a1.953 1.953 0 000-3.906h-3.154v-5.319h3.154z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});