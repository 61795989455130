define("ember-svg-jar/inlined/grad.tommy.burn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"99.655%\" id=\"burn-a\">\n      <stop stop-color=\"#24BBBC\" offset=\"0%\"/>\n      <stop stop-color=\"#B97BE2\" offset=\"100%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path d=\"M13.948 19.42c3.067-3.064 8.39-9.344 6.481-14.43 0 0 6.957 7.209 4.985 16.475 0 0 4.338-3.282 4.568-8.675 3.436 3.977 3.605 13.275 3.419 15.38C32.822 34.676 28.103 40 21.57 40c-6.534 0-11.83-5.297-11.83-11.83a8.668 8.668 0 0 1 .344-2.162 14.827 14.827 0 0 1 3.864-6.587Z\" fill=\"currentColor\"/>\n    <path d=\"M4.21 14.43C7.274 11.367 12.597 5.087 10.69 0c0 0 6.957 7.208 4.985 16.475 0 0 4.338-3.282 4.568-8.676 3.436 3.977 3.605 13.275 3.419 15.38-.579 6.508-5.298 11.83-11.831 11.83C5.297 35.01 0 29.714 0 23.18a8.668 8.668 0 0 1 .345-2.161 14.827 14.827 0 0 1 3.864-6.587h0Z\" stroke=\"url(#burn-a)\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\" transform=\"translate(7.551 1.49)\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});