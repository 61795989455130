define("@glimmer/reference", ["exports", "@glimmer/util", "@glimmer/validator"], function (_exports, _util, _validator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UPDATE_REFERENCED_VALUE = _exports.RootReference = _exports.ReferenceCache = _exports.PropertyReference = _exports.IterationItemReference = _exports.IterableReference = _exports.HelperRootReference = _exports.ConstReference = _exports.ComponentRootReference = _exports.CachedReference = void 0;
  _exports.isModified = isModified;
  class CachedReference {
    constructor() {
      this.lastRevision = null;
      this.lastValue = null;
    }
    value() {
      var {
        tag,
        lastRevision,
        lastValue
      } = this;
      if (lastRevision === null || !(0, _validator.validateTag)(tag, lastRevision)) {
        lastValue = this.lastValue = this.compute();
        this.lastRevision = (0, _validator.valueForTag)(tag);
      }
      return lastValue;
    }
    invalidate() {
      this.lastRevision = null;
    }
  } //////////
  _exports.CachedReference = CachedReference;
  class ReferenceCache {
    constructor(reference) {
      this.lastValue = null;
      this.lastRevision = null;
      this.initialized = false;
      this.tag = reference.tag;
      this.reference = reference;
    }
    peek() {
      if (!this.initialized) {
        return this.initialize();
      }
      return this.lastValue;
    }
    revalidate() {
      if (!this.initialized) {
        return this.initialize();
      }
      var {
        reference,
        lastRevision
      } = this;
      var tag = reference.tag;
      if ((0, _validator.validateTag)(tag, lastRevision)) return NOT_MODIFIED;
      var {
        lastValue
      } = this;
      var currentValue = reference.value();
      this.lastRevision = (0, _validator.valueForTag)(tag);
      if (currentValue === lastValue) return NOT_MODIFIED;
      this.lastValue = currentValue;
      return currentValue;
    }
    initialize() {
      var {
        reference
      } = this;
      var currentValue = this.lastValue = reference.value();
      this.lastRevision = (0, _validator.valueForTag)(reference.tag);
      this.initialized = true;
      return currentValue;
    }
  }
  _exports.ReferenceCache = ReferenceCache;
  var NOT_MODIFIED = (0, _util.symbol)('NOT_MODIFIED');
  function isModified(value) {
    return value !== NOT_MODIFIED;
  }
  class PrimitiveReference {
    constructor(inner) {
      this.inner = inner;
      this.tag = _validator.CONSTANT_TAG;
    }
    value() {
      return this.inner;
    }
    get(_key) {
      return UNDEFINED_REFERENCE;
    }
  }
  var UNDEFINED_REFERENCE = new PrimitiveReference(undefined);
  class ConstReference {
    constructor(inner) {
      this.inner = inner;
      this.tag = _validator.CONSTANT_TAG;
    }
    value() {
      return this.inner;
    }
    get(_key) {
      return UNDEFINED_REFERENCE;
    }
  }
  _exports.ConstReference = ConstReference;
  var UPDATE_REFERENCED_VALUE = _exports.UPDATE_REFERENCED_VALUE = (0, _util.symbol)('UPDATE_REFERENCED_VALUE');
  /**
   * RootReferences refer to a constant root value within a template. For
   * instance, the `this` in `{{this.some.prop}}`. This is typically a:
   *
   * - Component
   * - Controller
   * - Helper
   *
   * Or another "top level" template construct, if you will. PropertyReferences
   * chain off a root reference in the template, and can then be passed around and
   * used at will.
   */

  class RootReference {
    constructor(env) {
      this.env = env;
      this.children = (0, _util.dict)();
      this.tag = _validator.CONSTANT_TAG;
    }
    get(key) {
      // References should in general be identical to one another, so we can usually
      // deduplicate them in production. However, in DEBUG we need unique references
      // so we can properly key off them for the logging context.
      if (false /* DEBUG */) {
        // We register the template debug context now since the reference is
        // created before the component itself. It shouldn't be possible to cause
        // errors when accessing the root, only subproperties of the root, so this
        // should be fine for the time being. The exception is helpers, but they
        // set their context earlier.
        //
        // TODO: This points to a need for more first class support for arguments in
        // the debugRenderTree. The fact that we can't accurately relate an argument
        // reference to its component is problematic for debug tooling.
        if (!this.didSetupDebugContext) {
          this.didSetupDebugContext = true;
          this.env.setTemplatePathDebugContext(this, this.debugLogName || 'this', null);
        }
        return new PropertyReference(this, key, this.env);
      } else {
        var ref = this.children[key];
        if (ref === undefined) {
          ref = this.children[key] = new PropertyReference(this, key, this.env);
        }
        return ref;
      }
    }
  }
  _exports.RootReference = RootReference;
  class ComponentRootReference extends RootReference {
    constructor(inner, env) {
      super(env);
      this.inner = inner;
    }
    value() {
      return this.inner;
    }
  }
  _exports.ComponentRootReference = ComponentRootReference;
  class HelperRootReference extends RootReference {
    constructor(fn, args, env, debugName) {
      super(env);
      this.fn = fn;
      this.args = args;
      this.computeRevision = null;
      this.computeTag = null;
      if (false /* DEBUG */) {
        var name = debugName || fn.name;
        env.setTemplatePathDebugContext(this, `(result of a \`${name}\` helper)`, null);
        this.didSetupDebugContext = true;
      }
      if ((0, _validator.isConstTagged)(args)) {
        this.compute();
      }
      var {
        tag,
        computeTag
      } = this;
      if (computeTag !== null && (0, _validator.isConstTag)(computeTag)) {
        // If the args are constant, and the first computation is constant, then
        // the helper itself is constant and will never update.
        tag = this.tag = _validator.CONSTANT_TAG;
        this.computeRevision = (0, _validator.valueForTag)(tag);
      } else {
        var valueTag = this.valueTag = (0, _validator.createUpdatableTag)();
        tag = this.tag = (0, _validator.combine)([args.tag, valueTag]);
        if (computeTag !== null) {
          // We computed once, so setup the cache state correctly
          (0, _validator.updateTag)(valueTag, computeTag);
          this.computeRevision = (0, _validator.valueForTag)(tag);
        }
      }
    }
    compute() {
      this.computeTag = (0, _validator.track)(() => {
        this.computeValue = this.fn(this.args);
      }, false /* DEBUG */ && this.env.getTemplatePathDebugContext(this));
    }
    value() {
      var {
        tag,
        computeRevision
      } = this;
      if (computeRevision === null || !(0, _validator.validateTag)(tag, computeRevision)) {
        this.compute();
        (0, _validator.updateTag)(this.valueTag, this.computeTag);
        this.computeRevision = (0, _validator.valueForTag)(tag);
      }
      return this.computeValue;
    }
  }
  /**
   * PropertyReferences represent a property that has been accessed on a root, or
   * another property (or iterable, see below). `some` and `prop` in
   * `{{this.some.prop}}` are each property references, `some` being a property of
   * `this`, and `prop` being a property of `some`. They are constructed by
   * recursively calling `get` on the previous reference as a template chain is
   * followed.
   */
  _exports.HelperRootReference = HelperRootReference;
  class PropertyReference {
    constructor(parentReference, propertyKey, env) {
      this.parentReference = parentReference;
      this.propertyKey = propertyKey;
      this.env = env;
      this.children = (0, _util.dict)();
      this.lastRevision = null;
      if (false /* DEBUG */) {
        env.setTemplatePathDebugContext(this, propertyKey, parentReference);
      }
      var valueTag = this.valueTag = (0, _validator.createUpdatableTag)();
      var parentReferenceTag = parentReference.tag;
      this.tag = (0, _validator.combine)([parentReferenceTag, valueTag]);
    }
    value() {
      var {
        tag,
        lastRevision,
        lastValue,
        parentReference,
        valueTag,
        propertyKey
      } = this;
      if (lastRevision === null || !(0, _validator.validateTag)(tag, lastRevision)) {
        var parentValue = parentReference.value();
        if ((0, _util.isDict)(parentValue)) {
          var combined = (0, _validator.track)(() => {
            lastValue = this.env.getPath(parentValue, propertyKey);
          }, false /* DEBUG */ && this.env.getTemplatePathDebugContext(this));
          (0, _validator.updateTag)(valueTag, combined);
        } else {
          lastValue = undefined;
        }
        this.lastValue = lastValue;
        this.lastRevision = (0, _validator.valueForTag)(tag);
      }
      return lastValue;
    }
    get(key) {
      // References should in general be identical to one another, so we can usually
      // deduplicate them in production. However, in DEBUG we need unique references
      // so we can properly key off them for the logging context.
      if (false /* DEBUG */) {
        return new PropertyReference(this, key, this.env);
      } else {
        var ref = this.children[key];
        if (ref === undefined) {
          ref = this.children[key] = new PropertyReference(this, key, this.env);
        }
        return ref;
      }
    }
    [UPDATE_REFERENCED_VALUE](value) {
      var {
        parentReference,
        propertyKey
      } = this;
      var parentValue = parentReference.value();
      this.env.setPath(parentValue, propertyKey, value);
    }
  } //////////

  /**
   * IterationItemReferences represent an individual item in an iterable `each`.
   * They are similar to PropertyReferences, but since iteration items need to be
   * updated they have slightly different behavior. Concretely, they are the
   * `item` in:
   *
   * ```hbs
   * {{#each this.items as |item|}}
   *   {{item.foo}}
   * {{/each}}
   * ```
   *
   * Properties can chain off an iteration item, just like with the other template
   * reference types.
   */
  _exports.PropertyReference = PropertyReference;
  class IterationItemReference {
    constructor(parentReference, itemValue, itemKey, env) {
      this.parentReference = parentReference;
      this.itemValue = itemValue;
      this.env = env;
      this.tag = (0, _validator.createUpdatableTag)();
      this.children = (0, _util.dict)();
      if (false /* DEBUG */) {
        env.setTemplatePathDebugContext(this, (0, _util.debugToString)(itemKey), parentReference);
      }
    }
    value() {
      return this.itemValue;
    }
    update(value) {
      var {
        itemValue
      } = this; // TODO: refactor this https://github.com/glimmerjs/glimmer-vm/issues/1101

      if (value !== itemValue) {
        (0, _validator.dirtyTag)(this.tag);
        this.itemValue = value;
      }
    }
    get(key) {
      // References should in general be identical to one another, so we can usually
      // deduplicate them in production. However, in DEBUG we need unique references
      // so we can properly key off them for the logging context.
      if (false /* DEBUG */) {
        return new PropertyReference(this, key, this.env);
      } else {
        var ref = this.children[key];
        if (ref === undefined) {
          ref = this.children[key] = new PropertyReference(this, key, this.env);
        }
        return ref;
      }
    }
  }
  _exports.IterationItemReference = IterationItemReference;
  var NULL_IDENTITY = {};
  var KEY = (_, index) => index;
  var INDEX = (_, index) => String(index);
  var IDENTITY = item => {
    if (item === null) {
      // Returning null as an identity will cause failures since the iterator
      // can't tell that it's actually supposed to be null
      return NULL_IDENTITY;
    }
    return item;
  };
  function keyForPath(path, getPath) {
    if (false /* DEBUG */ && path[0] === '@') {
      throw new Error(`invalid keypath: '${path}', valid keys: @index, @identity, or a path`);
    }
    return uniqueKeyFor(item => getPath(item, path));
  }
  function makeKeyFor(key, getPath) {
    switch (key) {
      case '@key':
        return uniqueKeyFor(KEY);
      case '@index':
        return uniqueKeyFor(INDEX);
      case '@identity':
        return uniqueKeyFor(IDENTITY);
      default:
        return keyForPath(key, getPath);
    }
  }
  class WeakMapWithPrimitives {
    get weakMap() {
      if (this._weakMap === undefined) {
        this._weakMap = new WeakMap();
      }
      return this._weakMap;
    }
    get primitiveMap() {
      if (this._primitiveMap === undefined) {
        this._primitiveMap = new Map();
      }
      return this._primitiveMap;
    }
    set(key, value) {
      if ((0, _util.isObject)(key) || typeof key === 'function') {
        this.weakMap.set(key, value);
      } else {
        this.primitiveMap.set(key, value);
      }
    }
    get(key) {
      if ((0, _util.isObject)(key) || typeof key === 'function') {
        return this.weakMap.get(key);
      } else {
        return this.primitiveMap.get(key);
      }
    }
  }
  var IDENTITIES = new WeakMapWithPrimitives();
  function identityForNthOccurence(value, count) {
    var identities = IDENTITIES.get(value);
    if (identities === undefined) {
      identities = [];
      IDENTITIES.set(value, identities);
    }
    var identity = identities[count];
    if (identity === undefined) {
      identity = {
        value,
        count
      };
      identities[count] = identity;
    }
    return identity;
  }
  /**
   * When iterating over a list, it's possible that an item with the same unique
   * key could be encountered twice:
   *
   * ```js
   * let arr = ['same', 'different', 'same', 'same'];
   * ```
   *
   * In general, we want to treat these items as _unique within the list_. To do
   * this, we track the occurences of every item as we iterate the list, and when
   * an item occurs more than once, we generate a new unique key just for that
   * item, and that occurence within the list. The next time we iterate the list,
   * and encounter an item for the nth time, we can get the _same_ key, and let
   * Glimmer know that it should reuse the DOM for the previous nth occurence.
   */

  function uniqueKeyFor(keyFor) {
    var seen = new WeakMapWithPrimitives();
    return (value, memo) => {
      var key = keyFor(value, memo);
      var count = seen.get(key) || 0;
      seen.set(key, count + 1);
      if (count === 0) {
        return key;
      }
      return identityForNthOccurence(key, count);
    };
  }
  class IterableReference {
    constructor(parentRef, key, env) {
      this.parentRef = parentRef;
      this.key = key;
      this.env = env;
      this.iterator = null;
      this.tag = parentRef.tag;
    }
    value() {
      return !this.isEmpty();
    }
    isEmpty() {
      var iterator = this.iterator = this.createIterator();
      return iterator.isEmpty();
    }
    next() {
      var iterator = this.iterator;
      var item = iterator.next();
      if (item === null) {
        this.iterator = null;
      }
      return item;
    }
    createIterator() {
      var {
        parentRef,
        key,
        env
      } = this;
      var iterable = parentRef.value();
      var keyFor = makeKeyFor(key, env.getPath);
      if (Array.isArray(iterable)) {
        return new ArrayIterator(iterable, keyFor);
      }
      var maybeIterator = env.toIterator(iterable);
      if (maybeIterator === null) {
        return new ArrayIterator(_util.EMPTY_ARRAY, () => null);
      }
      return new IteratorWrapper(maybeIterator, keyFor);
    }
    childRefFor(key, value) {
      var {
        parentRef,
        env
      } = this;
      return new IterationItemReference(parentRef, value, false /* DEBUG */ ? `(key: ${(0, _util.debugToString)(key)}` : '', env);
    }
  }
  _exports.IterableReference = IterableReference;
  class IteratorWrapper {
    constructor(inner, keyFor) {
      this.inner = inner;
      this.keyFor = keyFor;
    }
    isEmpty() {
      return this.inner.isEmpty();
    }
    next() {
      var nextValue = this.inner.next();
      if (nextValue !== null) {
        nextValue.key = this.keyFor(nextValue.value, nextValue.memo);
      }
      return nextValue;
    }
  }
  class ArrayIterator {
    constructor(iterator, keyFor) {
      this.iterator = iterator;
      this.keyFor = keyFor;
      this.pos = 0;
      if (iterator.length === 0) {
        this.current = {
          kind: 'empty'
        };
      } else {
        this.current = {
          kind: 'first',
          value: iterator[this.pos]
        };
      }
    }
    isEmpty() {
      return this.current.kind === 'empty';
    }
    next() {
      var value;
      var current = this.current;
      if (current.kind === 'first') {
        this.current = {
          kind: 'progress'
        };
        value = current.value;
      } else if (this.pos >= this.iterator.length - 1) {
        return null;
      } else {
        value = this.iterator[++this.pos];
      }
      var {
        keyFor
      } = this;
      var key = keyFor(value, this.pos);
      var memo = this.pos;
      return {
        key,
        value,
        memo
      };
    }
  }
});