define("ember-svg-jar/inlined/minus-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <g id=\"Icon-/-outline.minus\" stroke=\"currentColor\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\">\n        <line x1=\"18\" y1=\"12\" x2=\"6\" y2=\"12\" id=\"Path\" stroke=\"currentColor\" stroke-width=\"1.5\"/>\n    </g>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "class": "w-6 h-6"
    }
  };
});