define("ember-svg-jar/inlined/burn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M35.298 46.81c6.807-6.803 18.624-20.746 14.388-32.037 0 0 15.445 16.004 11.067 36.577 0 0 9.631-7.287 10.141-19.262 7.63 8.83 8.004 29.473 7.59 34.146C77.2 80.683 66.723 92.5 52.219 92.5c-14.506 0-26.266-11.76-26.266-26.266.051-1.625.309-3.237.765-4.798a32.919 32.919 0 018.58-14.625z\"/>\n    <path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\" d=\"M30.44 39.038C37.247 32.234 49.064 18.292 44.828 7c0 0 15.446 16.004 11.067 36.577 0 0 9.631-7.287 10.141-19.261 7.63 8.829 8.004 29.472 7.59 34.145-1.283 14.45-11.761 26.266-26.266 26.266-14.506 0-26.266-11.76-26.266-26.266.051-1.625.309-3.237.765-4.797a32.919 32.919 0 018.58-14.626h0z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});