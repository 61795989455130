define("ember-svg-jar/inlined/pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <g id=\"pdf\" stroke=\"none\" stroke-width=\"1\" fill=\"currentColor\" fill-rule=\"evenodd\">\n        <path d=\"M377.67,450H148.8a50.17,50.17,0,0,1-50.11-50.11V98.11A50.17,50.17,0,0,1,148.8,48H298.9a15,15,0,0,1,10.6,4.39L423.38,166.27a15,15,0,0,1,4.4,10.61v223A50.17,50.17,0,0,1,377.67,450ZM148.8,78a20.13,20.13,0,0,0-20.11,20.11V399.89A20.13,20.13,0,0,0,148.8,420H377.67a20.13,20.13,0,0,0,20.11-20.11V183.09L292.68,78Z\"/>\n        <path d=\"M412.78,191.88H334a50.17,50.17,0,0,1-50.11-50.11V63a15,15,0,0,1,30,0v78.77A20.13,20.13,0,0,0,334,161.88h78.77a15,15,0,0,1,0,30Z\"/>\n        <path d=\"M156,267.36c0-3.31,3-6.22,7.68-6.22H191c17.47,0,31.24,8.21,31.24,30.45v.66c0,22.24-14.3,30.71-32.57,30.71H176.6v28.59c0,4.24-5.16,6.35-10.33,6.35S156,355.79,156,351.55Zm20.65,11.78v27.93h13.1c7.41,0,11.92-4.23,11.92-13.23v-1.46c0-9-4.51-13.24-11.92-13.24Z\"/>\n        <path d=\"M263.69,261.14c18.27,0,32.57,8.47,32.57,31.24v34.29c0,22.76-14.3,31.23-32.57,31.23H240.26c-5.42,0-9-2.91-9-6.22V267.36c0-3.31,3.58-6.22,9-6.22Zm-11.78,18V339.9h11.78c7.42,0,11.92-4.23,11.92-13.23V292.38c0-9-4.5-13.24-11.92-13.24Z\"/>\n        <path d=\"M309.36,267.5c0-4.24,4.5-6.36,9-6.36h45.93c4.37,0,6.23,4.64,6.23,8.87,0,4.9-2.25,9.13-6.23,9.13H330v22.38h20c4,0,6.22,3.83,6.22,8.07,0,3.57-1.85,7.81-6.22,7.81H330v34.15c0,4.24-5.16,6.35-10.32,6.35s-10.33-2.11-10.33-6.35Z\"/>\n    </g>\n",
    "attrs": {
      "viewBox": "0 0 512 512",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});