define("ember-svg-jar/inlined/bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <circle cx=\"49.656\" cy=\"59.492\" r=\"25.795\" fill=\"currentColor\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M83.65 18.983h-8.325L75.31 7.05c-.001-.569-.24-1.111-.66-1.495l-9.845-9.021A2.032 2.032 0 0063.433-4H35.88c-.508 0-.998.19-1.372.534l-9.846 9.02c-.42.385-.658.927-.66 1.496l-.014 11.933h-8.325c-1.122 0-2.032.91-2.032 2.031V97.97c0 1.122.91 2.031 2.032 2.031H83.65c1.121 0 2.03-.91 2.03-2.031V21.014c0-1.122-.909-2.031-2.03-2.031zM28.064 7.947L36.67.062h25.973l8.605 7.885.014 11.036h-5.767V7.848c0-1.122-.91-2.031-2.031-2.031H35.848c-1.121 0-2.03.91-2.03 2.031v11.135H28.05l.014-11.036zm9.816 11.036V9.879h23.553v9.104H37.88zm43.739 76.955H17.694V23.044h63.925v72.893z\"/>\n      <path d=\"M48.63 51.314c-1.122 0-2.031.91-2.031 2.031v12.293a2.031 2.031 0 104.062 0V53.345c0-1.121-.91-2.03-2.031-2.03zM42.434 58.443c.36-.678.564-1.45.564-2.27 0-2.679-2.18-4.859-4.86-4.859h-3.57c-1.122 0-2.032.91-2.032 2.031v12.293a2.03 2.03 0 002.032 2.031h.009s3.28-.015 4.185-.015a5.346 5.346 0 005.34-5.34 5.324 5.324 0 00-1.668-3.871zm-4.296-3.066a.798.798 0 010 1.594l-1.315.003h-.224v-1.597h1.54zm.624 8.215c-.41 0-1.304.003-2.163.006v-2.56l.234-.001h1.929c.704 0 1.277.573 1.277 1.277 0 .705-.573 1.278-1.277 1.278zM60.785 51.314c-4.508 0-8.177 3.669-8.177 8.178s3.669 8.177 8.177 8.177c4.51 0 8.178-3.668 8.178-8.177 0-4.51-3.669-8.178-8.178-8.178zm0 12.292a4.12 4.12 0 01-4.114-4.114 4.12 4.12 0 014.114-4.115 4.12 4.12 0 014.115 4.115 4.12 4.12 0 01-4.115 4.114z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});