define("ember-svg-jar/inlined/app.health.summary.bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"#F0EDF4\" transform=\"scale(-1 1) rotate(-11 66.426 912.08)\">\n    <path d=\"M112.714486,55.848381 C92.1315725,55.848381 75.4385965,72.5349401 75.4385965,93.1242701 C75.4385965,113.714883 92.1325351,130.406576 112.714486,130.406576 C133.305099,130.406576 150,113.716167 150,93.1242701 C150,72.5336567 133.306061,55.848381 112.714486,55.848381 L112.714486,55.848381 Z M133.280073,100.285553 L119.874806,100.285553 L119.874806,113.69467 L105.560903,113.69467 L105.560903,100.285553 L92.1517858,100.285553 L92.1517858,85.9677999 L105.560903,85.9677999 L105.562156,72.5625329 L119.876059,72.5625329 L119.876059,85.9677999 L133.281326,85.9677999 L133.280073,100.285553 Z\"/>\n    <path d=\"M112.714486,50.3651228 C124.236066,50.3651228 134.702383,54.9435953 142.396591,62.3743888 C153.69005,47.0440457 152.398003,25.3519681 138.518846,11.4753783 C123.222192,-3.82512609 98.4130957,-3.82512609 83.115158,11.4753783 L75,19.5931031 L66.8822752,11.4753783 C51.5830542,-3.82512609 26.7733159,-3.82512609 11.4753783,11.4753783 C-3.82512609,26.7781286 -3.82512609,51.5811291 11.4753783,66.8822752 L74.9996792,130.406576 L82.2825627,123.127222 C74.6663202,115.399325 69.9575842,104.804027 69.9575842,93.1255535 C69.9575842,69.5472221 89.1406461,50.3664061 112.713523,50.3664061 L112.714486,50.3651228 Z\"/>\n  </g>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "173",
      "height": "158",
      "viewBox": "0 0 173 158"
    }
  };
});