define('ember-browser-info/services/browser', ['exports', 'ember'], function (exports, _ember) {
  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var Promise = _ember['default'].RSVP.Promise;
  // jshint ignore:line
  var service = _ember['default'].inject.service;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observe = _ember['default'].observe;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  // jshint ignore:line
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var debug = _ember['default'].debug;
  // jshint ignore:line
  var a = _ember['default'].A; // jshint ignore:line
  var GET_IP = 'https://api.ipify.org?format=text';

  exports['default'] = _ember['default'].Service.extend({
    lookup: function lookup() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.getIpAddress().then(function (ip) {
          return _this.getGeoInfo(ip);
        }).then(function (geo) {
          return resolve(geo);
        })['catch'](reject);
      });
    },
    info: computed('ip', 'geo', function () {
      var _this2 = this;

      var _getProperties = this.getProperties('ip', 'geo', 'browser', 'os');

      var ip = _getProperties.ip;
      var geo = _getProperties.geo;
      var browser = _getProperties.browser;
      var os = _getProperties.os;

      if (!ip) {
        run.scheduleOnce('afterRender', function () {
          _this2.lookup()['catch'](console.error);
        });
      }
      return {
        os: os,
        browser: browser,
        ip: ip || { error: 'unresolved', message: 'use the lookup() method to get ip and geo' },
        geo: geo || { error: 'unresolved', message: 'use the lookup() method to get ip and geo' }
      };
    }),
    os: computed(function () {
      var appVersion = window.navigator.appVersion;
      var os = "Unknown";
      if (appVersion.indexOf("Win") !== -1) {
        os = "Windows";
      }
      if (appVersion.indexOf("Mac") !== -1) {
        os = "MacOS";
      }
      if (appVersion.indexOf("X11") !== -1) {
        os = "UNIX";
      }
      if (appVersion.indexOf("Linux") !== -1) {
        os = "Linux";
      }

      return os;
    }),
    browser: computed(function () {
      var nAgt = window.navigator.userAgent;
      var browserName = window.navigator.appName;
      var browserCode = undefined;
      var fullVersion = '' + parseFloat(window.navigator.appVersion);
      var majorVersion = parseInt(window.navigator.appVersion, 10);
      var nameOffset = undefined,
          verOffset = undefined,
          ix = undefined;

      // In Opera, the true version is after "Opera" or after "Version"
      if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
        browserName = 'Opera';
        browserCode = 'opera';
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
          fullVersion = nAgt.substring(verOffset + 8);
        }
      }
      // In MSIE, the true version is after "MSIE" in userAgent
      else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
          browserName = "Microsoft Internet Explorer";
          browserCode = 'ie';
          fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browserName = "Chrome";
            browserCode = 'chrome';
            fullVersion = nAgt.substring(verOffset + 7);
          }
          // In Safari, the true version is after "Safari" or after "Version"
          else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
              browserName = "Safari";
              browserCode = 'safari';
              fullVersion = nAgt.substring(verOffset + 7);
              if ((verOffset = nAgt.indexOf("Version")) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
              }
            }
            // In Firefox, the true version is after "Firefox"
            else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
                browserName = 'Firefox';
                browserCode = 'firefox';
                fullVersion = nAgt.substring(verOffset + 8);
              }
              // In most other browsers, "name/version" is at the end of userAgent
              else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
                  browserName = nAgt.substring(nameOffset, verOffset);
                  browserCode = 'other';
                  fullVersion = nAgt.substring(verOffset + 1);
                  if (browserName.toLowerCase() === browserName.toUpperCase()) {
                    browserName = navigator.appName;
                  }
                }
      // trim the fullVersion string at semicolon/space if present
      if ((ix = fullVersion.indexOf(";")) !== -1) {
        fullVersion = fullVersion.substring(0, ix);
      }
      if ((ix = fullVersion.indexOf(" ")) !== -1) {
        fullVersion = fullVersion.substring(0, ix);
      }

      majorVersion = parseInt('' + fullVersion, 10);
      if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
      }

      return {
        browserName: browserName,
        browserCode: browserCode,
        version: fullVersion,
        majorVersion: majorVersion,
        appName: window.navigator.appName,
        appVersion: window.navigator.appVersion,
        userAgent: window.navigator.userAgent
      };
    }),
    ip: null,
    geo: null,

    getIpAddress: function getIpAddress() {
      var _this3 = this;

      var ip = this.get('ip');
      if (ip) {
        return Promise.resolve(ip);
      }

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: GET_IP,
          method: 'GET'
        }).done(function (ip) {
          _this3.set('ip', ip);
          resolve(ip);
        }).fail(function (error) {
          _this3.set('ip', 'unknown');
          _this3.set('ipError', error);
          reject(error);
        });
      });
    },

    getGeoInfo: function getGeoInfo(ip) {
      var _this4 = this;

      var geo = this.get('geo');
      if (geo) {
        return Promise.resolve(geo);
      }

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: _this4.geoServiceUrl(ip),
          method: 'GET'
        }).done(function (geo) {
          _this4.set('geo', geo);
          resolve(geo);
        }).fail(function (error) {
          _this4.set('geo', 'unknown');
          _this4.set('geoError', error);
          reject(error);
        });
      });
    },

    protocol: computed({
      set: function set(_, value) {
        return value;
      },
      get: function get() {
        return window.location.protocol || 'https';
      }
    }),

    geoServiceUrl: function geoServiceUrl(ip) {
      var protocol = this.get('protocol');
      switch (this.get('geoService')) {
        case 'ipinfo':
          return protocol + '//ipinfo.io/' + ip + '/json';
        case 'freegeoip':
          return protocol + '//freegeoip.net/json/' + ip;
      }
    }
  });
});