define("ember-svg-jar/inlined/icon.question.smoking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <path fill=\"#06C1B5\" fill-rule=\"nonzero\" d=\"M68.972 16c-10.775 0-19.228 8.967-19.228 8.967S41.773 16 30.514 16c-10.95 0-19.893 10.09-20.478 22.306-.33 6.902 1.66 12.153 4.468 16.911C20.11 64.721 49.78 84 49.78 84s29.56-19.197 35.203-28.783c2.813-4.779 4.798-10.01 4.468-16.91C88.865 26.09 79.924 16 68.972 16\"/>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});