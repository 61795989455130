define("ember-math-helpers/helpers/tan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.tan = tan;
  /**
   * Executes `Math.tan` on the number passed to the helper.
   *
   * ```hbs
   * {{tan a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.tan`
   * @return {number} The tan of the passed number
   */
  function tan([number]) {
    return Math.tan(number);
  }
  var _default = _exports.default = Ember.Helper.helper(tan);
});