define("ember-svg-jar/inlined/nav.eat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"evenodd\">\n    <path d=\"M17.953 31.874c-5.398 0-9.79-4.391-9.79-9.79 0-5.048 3.841-9.215 8.754-9.735.356.612.777 1.233 1.275 1.86a.58.58 0 00.817.092.582.582 0 00.093-.817 14.728 14.728 0 01-.847-1.182c5.259.16 9.488 4.485 9.488 9.782 0 5.399-4.392 9.79-9.79 9.79m1.962-26.61a6.093 6.093 0 015.202-1.726 6.094 6.094 0 01-1.725 5.203 6.094 6.094 0 01-5.203 1.725 6.096 6.096 0 011.726-5.202m.84 6.232a7.254 7.254 0 003.46-1.933 7.258 7.258 0 001.973-6.646.581.581 0 00-.45-.45 7.258 7.258 0 00-6.645 1.974 7.251 7.251 0 00-2.13 5.293c-1.23-3.349-.515-5.933-.473-6.078a.581.581 0 00-1.115-.33c-.053.18-1.042 3.672.964 7.925C11.062 12.034 7 16.593 7 22.084c0 6.04 4.913 10.953 10.953 10.953 6.04 0 10.953-4.913 10.953-10.953 0-5.07-3.464-9.347-8.15-10.588\"/>\n    <path d=\"M14.225 14.024a8.67 8.67 0 00-4.394 5.029 8.67 8.67 0 00.449 6.663 8.677 8.677 0 002.097 2.716.216.216 0 00.307-.021.218.218 0 00-.022-.308 8.234 8.234 0 01-1.99-2.58 8.232 8.232 0 01-.428-6.33 8.235 8.235 0 014.174-4.778.218.218 0 00-.193-.391\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});