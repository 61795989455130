define("@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (or (is-array @values) (eq "select" @controlType))}}
    {{#if (has-block)}}
      <Quill::Toolbar::Select @type={{@type}} ...attributes>
        {{yield}}
      </Quill::Toolbar::Select>
    {{else}}
      <Quill::Toolbar::Select
        @selected={{@selected}}
        @type={{@type}}
        @values={{@values}}
        ...attributes
      />
    {{/if}}
  {{else}}
    {{#if (has-block)}}
      <Quill::Toolbar::Button @type={{@type}} ...attributes>
        {{yield}}
      </Quill::Toolbar::Button>
    {{else}}
      <Quill::Toolbar::Button @type={{@type}} ...attributes />
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "6aHrVkas",
    "block": "{\"symbols\":[\"@type\",\"&attrs\",\"&default\",\"@selected\",\"@values\",\"@controlType\"],\"statements\":[[6,[37,0],[[30,[36,3],[[30,[36,2],[[32,5]],null],[30,[36,1],[\"select\",[32,6]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"quill/toolbar/select\",[[17,2]],[[\"@type\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,3,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"quill/toolbar/select\",[[17,2]],[[\"@selected\",\"@type\",\"@values\"],[[32,4],[32,1],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"quill/toolbar/button\",[[17,2]],[[\"@type\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,3,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"quill/toolbar/button\",[[17,2]],[[\"@type\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"is-array\",\"or\"]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});