define("ember-svg-jar/inlined/milk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M18.986 45.234h46.532v37.1H18.986z\"/>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M82.738 26.2l-8.35-15.051V1.953A1.953 1.953 0 0072.433 0H30.626a1.953 1.953 0 00-1.953 1.953v8.903L17.419 25.982c-.386.443-.386 1.161-.386 1.165v70.9c0 1.079.874 1.953 1.953 1.953H81.03a1.953 1.953 0 001.953-1.953v-70.9c0-.007 0-.582-.245-.947zm-12.04-5.865l1.926-4.314 5.089 9.173h-9.184l2.169-4.86zM32.579 3.906h37.902v5.795H32.58V3.906zm-1.084 9.701h37.928L64.25 25.194H22.873l8.622-11.587zM20.939 29.101h42.625v66.993H20.94V29.1zm58.138 66.993H67.47V29.1h11.606v66.993z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});