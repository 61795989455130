define("@cloudcreativity/ember-quill/components/quill/toolbar/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" class={{this.qlClass}} ...attributes>
    {{yield}}
  </button>
  
  */
  {
    "id": "r4+2iV/R",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"button\"],[16,0,[32,0,[\"qlClass\"]]],[17,1],[24,4,\"button\"],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/button.hbs"
    }
  });
  class QuillToolbarButtonComponent extends _component.default {
    get qlClass() {
      if (this.args.type) {
        return `ql-${this.args.type}`;
      }
      return null;
    }
  }
  _exports.default = QuillToolbarButtonComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, QuillToolbarButtonComponent);
});