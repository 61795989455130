define("ember-svg-jar/inlined/grad.tommy.weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\".371%\" x2=\"50%\" y2=\"99.407%\" id=\"weight-a\">\n      <stop stop-color=\"#24BBBC\" offset=\"0%\"/>\n      <stop stop-color=\"#BA7EE2\" offset=\"100%\"/>\n    </linearGradient>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"weight-b\">\n      <stop stop-color=\"#24BBBC\" offset=\".371%\"/>\n      <stop stop-color=\"#BA7EE2\" offset=\"99.407%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"currentColor\" d=\"m12.466 19.176-5.87 20.549H40l-5.87-20.55z\"/>\n    <g stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\">\n      <path stroke=\"url(#weight-a)\" d=\"M33.405 33.787H0l5.871-20.549h21.663z\" transform=\"translate(2.194 1.495)\"/>\n      <path d=\"M10.2 6.502a6.502 6.502 0 1 1 13.004 0 6.502 6.502 0 0 1-13.004 0Z\" stroke=\"url(#weight-b)\" transform=\"translate(2.194 1.495)\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});