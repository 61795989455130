define("ember-svg-jar/inlined/app.sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"evenodd\">\n    <path d=\"M21.07 15.95h4.022a.354.354 0 000-.708h-3.165l3.416-3.416a.353.353 0 00-.25-.605h-3.735a.354.354 0 000 .708h2.879l-3.416 3.417a.352.352 0 00-.077.386.352.352 0 00.327.218M30.264 9.032h-5.868l6.116-6.116a.35.35 0 00-.248-.597h-6.233a.35.35 0 000 .7h5.388l-6.116 6.115c-.1.1-.13.25-.075.382a.349.349 0 00.323.216h6.713a.351.351 0 000-.7\"/>\n    <path d=\"M20.81 21.9a.628.628 0 00-1.257 0v1.15h-7.172V21.9a.628.628 0 00-1.256 0l.026 6.87c0 .516-.397.966-.913.981h-.03a.943.943 0 01-.942-.941v-6.44a4.267 4.267 0 014.267-4.266h4.895a4.267 4.267 0 014.266 4.266v6.44c0 .52-.422.942-.942.942h-.03c-.515-.016-.912-.466-.912-.982V21.9zm-7.33-8.648a2.658 2.658 0 012.656-2.655 2.658 2.658 0 012.655 2.655 2.659 2.659 0 01-2.655 2.656 2.659 2.659 0 01-2.656-2.656zm6.073 15.518c0 1.198.956 2.202 2.132 2.237l.067.001a2.2 2.2 0 002.198-2.198v-4.483h1.754v10.009a.628.628 0 001.257 0V23.699a.628.628 0 00-.629-.628H23.95v-.7a5.53 5.53 0 00-5.522-5.523h-.75a3.913 3.913 0 10-3.085 0h-1.06A5.53 5.53 0 008.01 22.37v.7H5.628A.628.628 0 005 23.7v10.637a.628.628 0 001.257 0v-10.01H8.01v4.484a2.2 2.2 0 002.265 2.197c1.176-.035 2.133-1.039 2.133-2.237v-4.463h7.145v4.463z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});