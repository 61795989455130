define("ember-svg-jar/inlined/toothbrush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M91.167 34.496a5.532 5.532 0 003.963-5.321c0-3.058-2.455-5.537-5.484-5.537H64.588c-3.03 0-5.484 2.479-5.484 5.537a5.537 5.537 0 003.588 5.197H52.479c-3.029 0-5.484 2.48-5.484 5.537 0 3.058 2.455 5.537 5.484 5.537h37.528c3.029 0 5.484-2.479 5.484-5.537 0-2.656-1.852-4.876-4.324-5.413z\"/>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M97.43 61.665h-1.686l1.908-15.99a1.935 1.935 0 00-1.92-2.163H46.832a1.934 1.934 0 00-1.92 2.163l1.254 10.516a24.135 24.135 0 00-17.334-7.329H2.263a1.934 1.934 0 00-1.935 1.935v10.89c0 1.068.866 1.934 1.935 1.934h29.082a24.399 24.399 0 008.4 8.993 24.115 24.115 0 0012.915 3.748h44.77a1.934 1.934 0 001.935-1.934V63.6a1.934 1.934 0 00-1.934-1.934zM56.427 47.38v6.06a1.934 1.934 0 003.869 0v-6.06h9.053v7.198a1.934 1.934 0 003.869 0v-7.198h9.053v6.06a1.934 1.934 0 003.869 0v-6.06h7.414l-1.704 14.284H50.716L49.012 47.38h7.414zm39.07 25.113H52.66c-7.778 0-14.995-4.57-18.387-11.644a1.934 1.934 0 00-1.744-1.097H4.197V52.73h24.637c7.587 0 14.505 4.209 18.056 10.984l.252.48c.217.844.979 1.452 1.87 1.452l46.484-.114v6.96z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});