define("ember-svg-jar/inlined/app.more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"nonzero\">\n    <path d=\"M8.7 15.6a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zM17.7 15.6a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zM26.8 15.6a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});