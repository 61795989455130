define("ember-svg-jar/inlined/grad.paragon.weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <title>icons/grad.weight</title>\n    <defs>\n        <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"linearGradient-1\">\n            <stop stop-color=\"#F57E8E\" offset=\"0%\"/>\n            <stop stop-color=\"#797696\" offset=\"100%\"/>\n        </linearGradient>\n        <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"linearGradient-2\">\n            <stop stop-color=\"#F57E8E\" offset=\"0%\"/>\n            <stop stop-color=\"#797696\" offset=\"100%\"/>\n        </linearGradient>\n    </defs>\n    <g id=\"--Icons\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n        <g id=\"Group-7\" transform=\"translate(2.194170, 1.494589)\">\n            <polygon id=\"Fill-1\" fill=\"#DEDEDE\" points=\"10.2720309 17.681007 4.40178541 38.2298015 37.8058305 38.2298015 31.935585 17.681007\"/>\n            <g id=\"Group-6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\">\n                <g id=\"Group\">\n                    <polygon id=\"Stroke-2\" stroke=\"url(#linearGradient-1)\" points=\"33.4046321 33.7871373 0 33.7871373 5.87083254 13.2383429 27.5337995 13.2383429\"/>\n                    <path d=\"M10.1999038,6.50247095 C10.1999038,2.91105475 13.1109585,0 16.7023747,0 C20.2937909,0 23.2048457,2.91105475 23.2048457,6.50247095 C23.2048457,10.0938872 20.2937909,13.0049419 16.7023747,13.0049419 C13.1109585,13.0049419 10.1999038,10.0938872 10.1999038,6.50247095 Z\" id=\"Stroke-4\" stroke=\"url(#linearGradient-2)\"/>\n                </g>\n            </g>\n        </g>\n    </g>\n",
    "attrs": {
      "width": "40px",
      "height": "40px",
      "viewBox": "0 0 40 40",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});