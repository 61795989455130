define("ember-svg-jar/inlined/nav.tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\">\n    <path d=\"M29.4 2.9H18.1c-.2 0-.5.1-.6.3L3.9 16.8c-1.2 1.2-1.2 3.3 0 4.5l10.3 10.3c.6.6 1.4.9 2.3.9.9 0 1.7-.3 2.3-.9L32.3 18c.2-.2.3-.4.3-.6V6.1c0-1.7-1.5-3.2-3.2-3.2zM31.2 17L17.4 30.8c-.3.3-.7.4-1.1.4-.4 0-.8-.2-1.1-.4L4.7 20.2c-.6-.6-.6-1.5 0-2.1L18.5 4.3h11.2c.8 0 1.5.7 1.5 1.5V17z\"/>\n    <path d=\"M26.8 8.7c-.4-.4-1-.7-1.7-.7s-1.2.2-1.7.7c-.4.4-.7 1-.7 1.7s.2 1.2.7 1.7c.4.4 1 .7 1.7.7.6 0 1.2-.2 1.7-.7.4-.4.7-1 .7-1.7s-.2-1.3-.7-1.7zm-.6 2.7c-.3.3-.7.4-1.1.4-.4 0-.8-.2-1.1-.4-.3-.3-.4-.7-.4-1.1s.2-.8.4-1.1c.3-.3.7-.4 1.1-.4s.8.2 1.1.4c.3.3.4.7.4 1.1s-.1.9-.4 1.1z\"/>\n    <path d=\"M19.3 6.7c.2.2.2.5 0 .7L7 19.7c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7L18.6 6.7c.2-.2.5-.2.7 0z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});