define("ember-svg-jar/inlined/water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M99.07 61.712c-3.79-6.22-9.246-11.752-15.78-16-6.601-4.29-14.364-7.305-22.449-8.716a1.94 1.94 0 00-.667 3.824c14.937 2.606 27.602 10.56 34.947 21.902C85.99 76.818 68.7 85.527 49.676 85.527c-19.023 0-36.312-8.709-45.444-22.805 6.943-10.709 18.82-18.509 32.781-21.483a1.94 1.94 0 10-.809-3.796C20.77 40.732 7.676 49.577.284 61.713a1.94 1.94 0 000 2.018c4.651 7.636 11.632 14.065 20.189 18.592 8.76 4.635 18.858 7.085 29.203 7.085 10.346 0 20.444-2.45 29.204-7.085 8.557-4.527 15.538-10.956 20.19-18.592a1.94 1.94 0 000-2.019z\"/>\n      <path d=\"M11.943 61.45a1.94 1.94 0 000 2.543c3.484 4.02 7.805 7.41 12.84 10.074C32.22 78 40.828 80.08 49.677 80.08c8.85 0 17.458-2.08 24.893-6.013 5.036-2.665 9.357-6.054 12.841-10.074a1.94 1.94 0 000-2.542c-3.485-4.02-7.805-7.409-12.84-10.073-4.643-2.457-9.723-4.19-15.1-5.15a1.94 1.94 0 10-.683 3.82c4.98.89 9.68 2.492 13.967 4.76 4.066 2.151 7.614 4.81 10.569 7.914-2.955 3.105-6.503 5.763-10.569 7.914-6.878 3.64-14.858 5.563-23.078 5.563-8.219 0-16.199-1.924-23.078-5.563-4.065-2.151-7.613-4.809-10.568-7.914 2.955-3.105 6.503-5.763 10.568-7.914 3.998-2.115 8.366-3.654 12.984-4.574a1.94 1.94 0 10-.76-3.806c-4.985.993-9.709 2.659-14.039 4.95-5.035 2.664-9.356 6.053-12.84 10.073z\"/>\n      <path d=\"M28.653 61.078a1.94 1.94 0 10-2.063 3.288c.804.504 1.646.99 2.504 1.443 6.111 3.234 13.229 4.943 20.582 4.943 7.354 0 14.471-1.709 20.582-4.943.859-.454 1.701-.94 2.504-1.443a1.94 1.94 0 10-2.063-3.288c-.722.454-1.481.892-2.256 1.301-5.554 2.94-12.043 4.492-18.767 4.492-6.723 0-13.213-1.553-18.767-4.492a35.593 35.593 0 01-2.256-1.3z\"/>\n    </g>\n    <path fill=\"currentColor\" d=\"M69.473 39.917c0 10.397-8.863 18.826-19.797 18.826-10.933 0-19.797-8.429-19.797-18.826C29.88 28.24 49.676 9.54 49.676 9.54s19.797 18.7 19.797 30.377z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});