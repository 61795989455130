define("ember-svg-jar/inlined/tablets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M19.371 38.503h38.223v34.663H19.371z\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M89.305 68.834h-12.06v-5.958c0-5.959-4.845-10.806-10.799-10.806-2.624 0-5.032.943-6.906 2.507V29.465h1.049a1.946 1.946 0 001.946-1.946V12.456a1.946 1.946 0 00-1.946-1.946H1.946A1.946 1.946 0 000 12.456V27.52c0 1.075.871 1.946 1.946 1.946h1.049v58.08c0 1.074.87 1.945 1.945 1.945h52.654a1.946 1.946 0 001.946-1.946v-.56a10.743 10.743 0 006.906 2.506h22.859c5.69 0 10.32-4.633 10.32-10.328 0-5.695-4.63-10.328-10.32-10.328zM66.446 55.962c3.809 0 6.907 3.101 6.907 6.914v5.958H59.54v-5.958c0-3.813 3.098-6.914 6.906-6.914zM3.892 14.402h54.75v11.171h-7.04V21.22a1.946 1.946 0 00-3.892 0v4.354h-8.367V21.22a1.946 1.946 0 00-3.892 0v4.354h-8.368V21.22a1.946 1.946 0 00-3.891 0v4.354h-8.368V21.22a1.946 1.946 0 00-3.891 0v4.354H3.892V14.402zm51.756 71.196H6.886V29.465h48.762v56.133zm3.892-6.914v-5.958h13.813v5.958c0 3.813-3.099 6.914-6.907 6.914s-6.906-3.101-6.906-6.914zm29.765 6.914H74.737a10.76 10.76 0 002.507-6.914v-5.958h12.06c3.546 0 6.43 2.887 6.43 6.436s-2.884 6.436-6.429 6.436z\"/>\n      <path d=\"M41.455 52.534h-4.89l2.553-4.844a1.946 1.946 0 10-3.443-1.815l-4.057 7.698a1.946 1.946 0 001.721 2.853h4.775l-4.253 7.501a1.946 1.946 0 003.385 1.92l5.901-10.407a1.946 1.946 0 00-1.692-2.906z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});