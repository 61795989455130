define("ember-svg-jar/inlined/nav.help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"currentColor\" d=\"M11.713 15.34a.846.846 0 00-.837.848c0 .451.363.848.837.848a.856.856 0 00.848-.848.856.856 0 00-.848-.849zM11.858 8C10.131 8 9.536 9.12 9.536 9.5c0 .499.244.686.59.686.69 0 .409-.986 1.714-.986.64 0 1.152.282 1.152.87 0 .692-.717 1.088-1.14 1.447-.37.32-.857.844-.857 1.945 0 .665.065.848.59.848.3 0 .624-.055.624-.298 0-.666-.123-1.213.58-1.763.346-.269 1.397-.976 1.397-2.179 0-1.203-.69-2.07-2.328-2.07z\"/>\n    <path fill=\"#6D6D6D\" d=\"M12 2.601A9.393 9.393 0 002.601 12v8.664c0 .406.33.735.735.735H12A9.393 9.393 0 0021.399 12 9.393 9.393 0 0012 2.601zm0 17.821H3.578V12A8.418 8.418 0 0112 3.578 8.418 8.418 0 0120.422 12 8.418 8.418 0 0112 20.422z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});