define("ember-analytic-sauce/services/analytics", ["exports", "ember-get-config"], function (exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // Attributes
    viewSequence: 0,
    eventSequence: 0,
    user: null,
    ip: null,
    // Services
    cordovaPlatform: Ember.inject.service("ember-cordova/platform"),
    // Computed
    url: Ember.computed(function () {
      const url = Ember.get(_emberGetConfig.default, "analytics-sauce.apiUrl");
      if (!url) {
        throw Error("please set a config value for analytics-sauce.apiUrl");
      }
      return url;
    }),
    globalSequence: Ember.computed("viewSequence", "eventSequence", function () {
      return Ember.get(this, "viewSequence") + Ember.get(this, "eventSequence");
    }),
    isWeb: Ember.computed("cordovaPlatform.isCordova", function () {
      return !this.get("cordovaPlatform.isCordova");
    }),
    isApp: Ember.computed.not("isWeb"),
    platform: Ember.computed("isWeb", "isApp", function () {
      if (Ember.get(this, "isWeb")) {
        return "web";
      }
      if (Ember.get(this, "isWeb")) {
        return "app";
      }
    }),
    userAgent: Ember.computed(function () {
      return navigator.userAgent || navigator.vendor || window.opera;
    }),
    iOS: Ember.computed("userAgent", function () {
      const userAgent = this.get("userAgent");
      return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i);
    }),
    android: Ember.computed("userAgent", function () {
      const userAgent = this.get("userAgent");
      return userAgent.match(/Android/i);
    }),
    // Methods
    init() {
      this._super(...arguments);
      // create session hash
      const session = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      Ember.set(this, "session", session);
    },
    setUser(user) {
      Ember.set(this, "user", user);
    },
    setAppName(name) {
      Ember.set(this, "appName", name);
    },
    baseProperties() {
      const row = {};
      // ember app details
      Ember.set(row, "environment", Ember.get(_emberGetConfig.default, "environment"));
      Ember.set(row, "appName", this.get("appName") || Ember.get(_emberGetConfig.default, "APP.name"));
      // package version
      const v = Ember.get(_emberGetConfig.default, "APP.version").split("+");
      Ember.set(row, "appVersion", v[0]);
      Ember.set(row, "appHash", v[1]);
      // System setting
      Ember.set(row, "userAgent", window.navigator.userAgent);
      // User details
      Ember.set(row, "sessionId", Ember.get(this, "session"));
      Ember.set(row, "userId", Ember.get(this, "user"));
      return row;
    },
    trackVisit(view, title) {
      this.log("Track View");

      const row = this.baseProperties();

      this.incrementProperty("viewSequence");
      Ember.set(row, "viewSequence", Ember.get(this, "viewSequence"));
      Ember.set(row, "globalSequence", Ember.get(this, "globalSequence"));

      Ember.set(row, "name", view);
      Ember.set(row, "title", title);

      return this.send("/visits", row);
    },
    trackEvent(event, data) {
      this.log("Track Event");

      const row = this.baseProperties();

      this.incrementProperty("eventSequence");
      Ember.set(row, "eventSequence", Ember.get(this, "eventSequence"));
      Ember.set(row, "globalSequence", Ember.get(this, "globalSequence"));

      Ember.set(row, "name", event);
      Ember.set(row, "data", data);

      return this.send("/events", row);
    },
    send(uri, data) {
      const url = `${Ember.get(this, "url")}${uri}`;

      data = JSON.stringify(data);

      if (this.shouldSendData()) {
        return fetch(url, {
          method: "POST",
          headers: { accept: "application/json" },
          body: data
        }).then(response => {
          this.log("analytics sent", data);
        });
      } else {
        this.log(url, data);
      }
    },
    shouldSendData() {
      const currentEnv = _emberGetConfig.default.environment,
            activeEnvs = Ember.get(_emberGetConfig.default, "analytics-sauce.environments");

      if (activeEnvs) {
        return activeEnvs.indexOf(currentEnv) !== -1;
      }
      return false;
    },
    log(...args) {
      if (Ember.get(_emberGetConfig.default, "analytics-sauce.debug")) {
        window.console.log(...args);
      }
    }
  });
});