define("ember-svg-jar/inlined/watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M74.605 44.54c.34 1.767.517 3.593.517 5.46s-.177 3.692-.517 5.46h8.409V44.54h-8.409zM53.784 38.273c-4.182 0-7.464 3.48-7.464 3.48s-3.094-3.48-7.464-3.48c-4.251 0-7.722 3.916-7.95 8.658-.127 2.68.646 4.718 1.735 6.565 2.176 3.689 13.693 11.172 13.693 11.172s11.474-7.451 13.665-11.172c1.092-1.855 1.862-3.886 1.734-6.565-.227-4.742-3.698-8.658-7.949-8.658\"/>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M77.075 50c0-10.495-5.285-19.777-13.332-25.33l-2.156-22.9A1.953 1.953 0 0059.643 0H32.997c-1.008 0-1.85.767-1.944 1.77l-2.156 22.9C20.85 30.223 15.564 39.505 15.564 50c0 10.495 5.286 19.777 13.333 25.33l2.156 22.9a1.953 1.953 0 001.944 1.77h26.646c1.008 0 1.85-.767 1.944-1.77l2.156-22.9C71.79 69.777 77.075 60.495 77.075 50zm-42.3-46.094h23.09l1.728 18.353a30.572 30.572 0 00-13.273-3.015 30.57 30.57 0 00-13.273 3.015l1.728-18.353zm23.09 92.188h-23.09L33.047 77.74a30.572 30.572 0 0013.273 3.015 30.57 30.57 0 0013.273-3.015l-1.728 18.353zM46.32 76.849C31.515 76.85 19.47 64.805 19.47 50s12.045-26.85 26.85-26.85c14.805 0 26.85 12.045 26.85 26.85 0 14.805-12.045 26.85-26.85 26.85z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});