define("ember-svg-jar/inlined/shape.curve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <path fill-rule=\"evenodd\" d=\"M414,56 C353.400255,20.6460744 282.218578,0 207,0 C131.282483,0 60.3588523,20.5250706 -0.510586696,56.3149078 L414,56 Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 414 56",
      "fill": "currentColor",
      "preserveAspectRatio": "none"
    }
  };
});