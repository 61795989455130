define("ember-svg-jar/inlined/ying-yang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" transform=\"translate(39.648 15.625)\">\n      <ellipse cx=\"10.352\" cy=\"10.365\" rx=\"10.202\" ry=\"10.198\"/>\n      <ellipse cx=\"10.352\" cy=\"58.385\" rx=\"10.202\" ry=\"10.198\"/>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M85.355 14.659C75.91 5.219 63.355.019 50 .019c-13.355 0-25.91 5.2-35.354 14.64C5.2 24.099 0 36.65 0 50c0 13.35 5.201 25.901 14.645 35.341 9.444 9.44 22 14.64 35.355 14.64 13.355 0 25.91-5.2 35.355-14.64C94.799 75.901 100 63.35 100 50c0-13.35-5.201-25.901-14.645-35.341zM3.906 50c0-19.032 11.607-35.407 28.115-42.425-4.465 4.548-7.223 10.777-7.223 17.637 0 13.89 11.306 25.192 25.202 25.192 12.597 0 22.845 10.244 22.845 22.835 0 12.591-10.248 22.835-22.845 22.835C24.584 96.074 3.906 75.405 3.906 50zm65.218 41.918c4.716-4.824 7.627-11.417 7.627-18.679 0-14.745-12-26.741-26.751-26.741-11.742 0-21.296-9.55-21.296-21.286 0-11.737 9.554-21.286 21.296-21.286 25.416 0 46.094 20.669 46.094 46.074 0 18.59-11.072 34.642-26.97 41.918z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});