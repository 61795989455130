define("ember-svg-jar/inlined/monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M89.031 18.642V59.98H10.525V18.642z\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M90.823 8.116H8.733C3.917 8.116 0 12.034 0 16.85v54.675c0 4.816 3.917 8.733 8.733 8.733h25.616l-7.072 8.433a1.945 1.945 0 001.49 3.194h42.021a1.945 1.945 0 001.49-3.194l-7.072-8.433h25.617c4.815 0 8.733-3.917 8.733-8.733V16.85c0-4.815-3.918-8.733-8.733-8.733zM66.62 87.995H32.936l6.489-7.738H60.13l6.489 7.738zm29.047-16.47a4.85 4.85 0 01-4.844 4.843H8.733a4.85 4.85 0 01-4.844-4.844V16.85a4.85 4.85 0 014.844-4.844h82.09a4.85 4.85 0 014.844 4.844v54.675z\"/>\n      <path d=\"M36.545 28.39c-2.298 0-4.225 1.04-5.455 1.933-1.22-.895-3.138-1.933-5.48-1.933-4.134 0-7.545 3.6-7.765 8.195-.13 2.722.815 4.665 1.538 5.89 1.738 2.946 9.172 7.87 10.645 8.827a1.942 1.942 0 002.119 0c1.467-.953 8.877-5.859 10.626-8.83.721-1.226 1.666-3.171 1.536-5.888-.22-4.595-3.63-8.194-7.764-8.194zM39.42 40.5c-.85 1.444-5.07 4.636-8.333 6.839-3.276-2.21-7.51-5.408-8.355-6.84-.766-1.299-1.066-2.414-1.003-3.728.118-2.477 1.86-4.492 3.88-4.492 2.257 0 4.005 1.887 4.014 1.897a1.94 1.94 0 001.422.656c.549.009 1.066-.21 1.44-.607.019-.02 1.897-1.946 4.059-1.946 2.02 0 3.762 2.015 3.88 4.491.063 1.312-.237 2.428-1.004 3.73zM79.778 36.456h-5.922l-1.127-2.264a1.945 1.945 0 00-3.55.154l-2.535 6.44-2.376-8.107a1.944 1.944 0 00-3.679-.156l-2.017 5.204-.521-.78a1.945 1.945 0 00-1.617-.866h-6.621a1.944 1.944 0 100 3.89h5.581l2.022 3.028a1.945 1.945 0 003.43-.377l1.3-3.356 2.363 8.06a1.945 1.945 0 003.676.166l3.052-7.757c.362.384.871.61 1.414.61h7.127a1.944 1.944 0 000-3.889zM52.732 66.085h-5.909a1.944 1.944 0 100 3.89h5.909a1.944 1.944 0 100-3.89z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});