define("ember-svg-jar/inlined/fruit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M58.586 52.306c-4.955 0-8.843 4.123-8.843 4.123s-3.666-4.123-8.844-4.123c-5.036 0-9.148 4.64-9.418 10.258-.151 3.174.764 5.59 2.055 7.778 2.579 4.37 16.224 13.237 16.224 13.237s13.594-8.829 16.19-13.237c1.293-2.198 2.206-4.604 2.054-7.778-.269-5.618-4.381-10.258-9.418-10.258\"/>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M63.857 30.266h-.023c-3.538.04-8.407.86-12.142 2.346v-5.107c1.077.124 2.55.244 4.239.244 4.57 0 10.706-.876 14.742-4.903 6.989-6.975 4.508-20.23 4.4-20.79A1.95 1.95 0 0073.528.514C72.968.405 59.691-2.07 52.704 4.903c-.49.49-.933 1.012-1.335 1.557a1.95 1.95 0 00-3.575 1.074v25.078c-3.734-1.485-8.604-2.306-12.142-2.346h-.022c-14.65 0-26.57 11.87-26.57 26.459 0 7.323 3.389 18.024 8.432 26.626C23.712 93.965 31.28 99.81 38.8 99.81c3.054 0 7.957-1.706 10.942-3.682 2.985 1.976 7.888 3.682 10.942 3.682 7.516 0 15.082-5.844 21.305-16.457 5.046-8.605 8.435-19.306 8.435-26.627 0-14.59-11.918-26.459-26.568-26.459zM55.458 7.663c4.4-4.392 12.732-3.905 15.983-3.517.389 3.248.874 11.552-3.522 15.94-4.402 4.392-12.736 3.904-15.986 3.516a34.333 34.333 0 01-.24-3.495v-1.125c.066-3.74.829-8.39 3.765-11.32zM78.627 81.38c-5.415 9.235-11.954 14.53-17.942 14.53-2.596 0-7.73-1.956-9.638-3.673-.37-.334-.837-.5-1.304-.5-.467 0-.933.166-1.304.5-1.908 1.717-7.043 3.674-9.638 3.674-5.992 0-12.533-5.296-17.946-14.53-4.723-8.057-7.896-17.966-7.896-24.656 0-12.436 10.163-22.554 22.659-22.56 3.658.043 9.053 1.08 12.175 2.722v3.634a1.95 1.95 0 103.899 0v-3.634c3.122-1.643 8.517-2.679 12.176-2.722 12.495.006 22.658 10.124 22.658 22.56 0 6.688-3.174 16.596-7.9 24.655z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});