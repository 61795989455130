define("ember-svg-jar/inlined/video.play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M56 40L32 22v36l24-18zM0 40c0 22 18 40 40 40s40-18 40-40S62 0 40 0 0 18 0 40zm8 0C8 22.4 22.4 8 40 8s32 14.4 32 32-14.4 32-32 32S8 57.6 8 40z\"/>\n",
    "attrs": {
      "viewBox": "0 0 80 80"
    }
  };
});