define("ember-svg-jar/inlined/splash.gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"evenodd\">\n    <rect width=\"112\" height=\"112\" x=\"8\" y=\"8\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"128\" y=\"8\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"248\" y=\"8\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"8\" y=\"128\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"128\" y=\"128\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"248\" y=\"128\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"8\" y=\"248\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"128\" y=\"248\" opacity=\".2\" rx=\"8\"/>\n    <rect width=\"112\" height=\"112\" x=\"248\" y=\"248\" opacity=\".2\" rx=\"8\"/>\n    <path fill-rule=\"nonzero\" d=\"M48 63.954c-.029-8.885 7.437-16.185 16.442-15.948 8.32.223 15.593 7.088 15.558 16.066-.033 8.83-7.223 15.96-16.06 15.928-8.836-.033-15.972-7.217-15.94-16.046zm23.997.508a4.165 4.165 0 00-.737-.756 1166.02 1166.02 0 00-9.771-5.499c-.815-.457-1.465-.137-1.465.757a626.5 626.5 0 000 11.047c0 .9.65 1.245 1.461.787a869.51 869.51 0 009.843-5.542c.24-.146.383-.464.672-.794h-.003zM168 303.954c-.029-8.885 7.437-16.185 16.442-15.948 8.32.223 15.593 7.088 15.558 16.066-.033 8.83-7.223 15.96-16.06 15.928-8.836-.033-15.972-7.217-15.94-16.046zm23.997.508a4.165 4.165 0 00-.737-.756c-3.246-1.848-6.503-3.68-9.771-5.499-.815-.457-1.465-.137-1.465.757a626.504 626.504 0 000 11.047c0 .9.65 1.245 1.461.787a869.52 869.52 0 009.843-5.542c.24-.146.383-.464.672-.794h-.003zM200 194.333h-32L178.667 173l8 16L192 178.333zM320 194.333h-32L298.667 173l8 16L312 178.333zM200 74.333h-32L178.667 53l8 16L192 58.333zM320 74.333h-32L298.667 53l8 16L312 58.333zM80 194.333H48L58.667 173l8 16L72 178.333zM320 309.333h-32L298.667 288l8 16L312 293.333zM80 309.333H48L58.667 288l8 16L72 293.333z\"/>\n  </g>\n",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 368 368"
    }
  };
});