define("ember-cli-flash/utils/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.guidFor = guidFor;
  function add(...dependentKeys) {
    const computedFunc = Ember.computed({
      get() {
        const values = dependentKeys.map(dependentKey => {
          const value = Ember.get(this, dependentKey);
          if (Ember.typeOf(value) !== 'number') {
            return;
          }
          return value;
        });
        return Ember.A(values).compact().reduce((prev, curr) => {
          return prev + curr;
        });
      }
    });
    return computedFunc.property.apply(computedFunc, dependentKeys);
  }
  function guidFor(dependentKey) {
    return Ember.computed(dependentKey, {
      get() {
        const value = Ember.get(this, dependentKey);

        // it's possible that a value has no toString as some objects don't implement the guid field
        // this early return it to avoid errors being thrown when calling undefined.toString()
        if (Ember.isNone(value)) return;
        return Ember.guidFor(value.toString());
      }
    });
  }
});