define("ember-concurrency/-private/utils", ["exports", "ember-concurrency/-private/ember-environment", "ember-concurrency/-private/external/yieldables"], function (_exports, _emberEnvironment, _yieldables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assignProperties = _exports.USE_TRACKED = _exports.EmberYieldable = void 0;
  _exports.deprecatePrivateModule = deprecatePrivateModule;
  _exports.isEventedObject = isEventedObject;
  _exports.timeout = timeout;
  const USE_TRACKED = _exports.USE_TRACKED = true;
  const assignProperties = _exports.assignProperties = USE_TRACKED ? Object.assign : Ember.setProperties;
  function isEventedObject(c) {
    return c && (typeof c.one === 'function' && typeof c.off === 'function' || typeof c.on === 'function' && typeof c.off === 'function' || typeof c.addEventListener === 'function' && typeof c.removeEventListener === 'function');
  }
  class EmberYieldable extends _yieldables.Yieldable {
    _deferable() {
      return _emberEnvironment.EMBER_ENVIRONMENT.defer();
    }
  }
  _exports.EmberYieldable = EmberYieldable;
  class TimeoutYieldable extends EmberYieldable {
    constructor(ms) {
      super();
      this.ms = ms;
    }
    onYield(state) {
      let timerId = Ember.run.later(() => state.next(), this.ms);
      return () => Ember.run.cancel(timerId);
    }
  }

  /**
   *
   * Yielding `timeout(ms)` will pause a task for the duration
   * of time passed in, in milliseconds.
   *
   * This timeout will be scheduled on the Ember runloop, which
   * means that test helpers will wait for it to complete before
   * continuing with the test. See `rawTimeout()` if you need
   * different behavior.
   *
   * The task below, when performed, will print a message to the
   * console every second.
   *
   * ```js
   * export default class MyComponent extends Component {
   *   &#64;task *myTask() {
   *     while (true) {
   *       console.log("Hello!");
   *       yield timeout(1000);
   *     }
   *   }
   * }
   * ```
   *
   * @param {number} ms - the amount of time to sleep before resuming
   *   the task, in milliseconds
   */
  function timeout(ms) {
    return new TimeoutYieldable(ms);
  }
  function deprecatePrivateModule(moduleName) {
    // eslint-disable-next-line no-console
    console.warn(`an Ember addon is importing a private ember-concurrency module '${moduleName}' that has moved`);
  }
});