define("ember-svg-jar/inlined/grad.tommy.bmi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"bmi-a\">\n      <stop stop-color=\"#35BEC1\" offset=\"0%\"/>\n      <stop stop-color=\"#B77CE2\" offset=\"100%\"/>\n    </linearGradient>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"98.94%\" id=\"bmi-b\">\n      <stop stop-color=\"#35BEC1\" offset=\"0%\"/>\n      <stop stop-color=\"#B77CE2\" offset=\"100%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path d=\"M5.442 22.596c0-9.543 7.736-17.279 17.28-17.279C32.263 5.317 40 13.053 40 22.596s-7.736 17.279-17.279 17.279S5.442 32.139 5.442 22.596\" fill=\"currentColor\"/>\n    <path d=\"M0 18.186C0 8.142 8.142 0 18.186 0s18.186 8.142 18.186 18.186-8.142 18.187-18.186 18.187S0 28.23 0 18.186Z\" stroke=\"url(#bmi-a)\" stroke-width=\"3\" transform=\"translate(1.484 1.444)\"/>\n    <path d=\"M19.357 10.928a1.17 1.17 0 0 0-2.342 0l-1.278 12.683a2.503 2.503 0 0 0 2.45 3.004 2.5 2.5 0 0 0 2.449-3.004l-1.279-12.683Z\" fill=\"url(#bmi-b)\" transform=\"translate(1.484 1.444)\"/>\n    <path d=\"M20.981 7.053a1.312 1.312 0 1 1-2.623-.001 1.312 1.312 0 0 1 2.623 0\" fill=\"#35BEC1\"/>\n    <path d=\"M20.981 32.208a1.312 1.312 0 1 1-2.623 0 1.312 1.312 0 0 1 2.623 0\" fill=\"#B77CE2\"/>\n    <path d=\"M32.248 20.942a1.312 1.312 0 1 1 0-2.624 1.312 1.312 0 0 1 0 2.624\" fill=\"#35BEC1\"/>\n    <path d=\"M7.092 20.942a1.312 1.312 0 1 1 0-2.624 1.312 1.312 0 0 1 0 2.624\" fill=\"#B77CE2\"/>\n    <path d=\"M29.491 11.664a1.312 1.312 0 1 1-1.855-1.855 1.312 1.312 0 0 1 1.855 1.855\" fill=\"#35BEC1\"/>\n    <path d=\"M11.704 29.451a1.312 1.312 0 1 1-1.855-1.855 1.312 1.312 0 0 1 1.855 1.855\" fill=\"#B77CE2\"/>\n    <path d=\"M27.636 29.451a1.312 1.312 0 1 1 1.855-1.854 1.312 1.312 0 0 1-1.855 1.854\" fill=\"#35BEC1\"/>\n    <path d=\"M9.849 11.664a1.312 1.312 0 1 1 1.855-1.855 1.312 1.312 0 0 1-1.855 1.855\" fill=\"#B77CE2\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});