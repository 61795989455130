define("ember-math-helpers/helpers/ceil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ceil = ceil;
  _exports.default = void 0;
  /**
   * Executes `Math.ceil` on the number passed to the helper.
   *
   * ```hbs
   * {{ceil a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.ceil`
   * @return {number} The ceil of the passed number
   */
  function ceil([number]) {
    return Math.ceil(number);
  }
  var _default = _exports.default = Ember.Helper.helper(ceil);
});