define("ember-svg-jar/inlined/no-fruit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M86.018 30.28a9.006 9.006 0 001.798-5.408c0-4.338-3.07-7.973-7.157-8.86v-1.455a5.209 5.209 0 013.462-4.892 1.936 1.936 0 10-1.299-3.649 9.096 9.096 0 00-5.98 7.554 34.039 34.039 0 00-10.434-9.186A33.932 33.932 0 0049.714 0C32.348 0 17.912 13.065 16.071 30.069h-.886c-4.186 0-7.592 3.406-7.592 7.592s3.406 7.591 7.592 7.591h.391l6.6 23.064c.237.831.997 1.404 1.861 1.404h10.41a1.937 1.937 0 000-3.873h-8.95l-5.893-20.595h60.22l-5.893 20.595H63.504a1.937 1.937 0 000 3.873H75.39c.865 0 1.624-.573 1.862-1.404l6.6-23.063h.39c4.187 0 7.592-3.406 7.592-7.592 0-3.575-2.484-6.58-5.817-7.38zm-2.074-5.408c0 2.865-2.342 5.197-5.221 5.197s-5.22-2.332-5.22-5.197c0-2.866 2.341-5.197 5.22-5.197 2.879 0 5.22 2.332 5.22 5.197zm-34.23-21c9.984 0 19.13 4.836 24.733 12.999-2.864 1.528-4.817 4.54-4.817 8 0 1.933.61 3.725 1.647 5.198H19.968c1.82-14.846 14.51-26.196 29.746-26.196zm34.53 37.508h-69.06a3.723 3.723 0 01-3.718-3.72c0-2.05 1.668-3.718 3.719-3.718h69.059a3.723 3.723 0 013.719 3.719 3.723 3.723 0 01-3.72 3.719z\"/>\n      <path d=\"M34.446 48.457c-1.07 0-1.936.867-1.936 1.936v6.49a1.937 1.937 0 003.872 0v-6.49c0-1.07-.866-1.936-1.936-1.936zM64.982 58.82c1.07 0 1.937-.867 1.937-1.936v-6.49a1.937 1.937 0 00-3.873 0v6.49c0 1.069.867 1.936 1.936 1.936zM49.714 48.457c-1.07 0-1.936.867-1.936 1.936v8.56a1.937 1.937 0 003.873 0v-8.56c0-1.07-.867-1.936-1.937-1.936z\"/>\n    </g>\n    <g transform=\"translate(28.994 57.9)\">\n      <ellipse cx=\"20.72\" cy=\"20.645\" fill=\"currentColor\" rx=\"20.669\" ry=\"20.601\"/>\n      <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M23.463 20.71l3.762-3.75a1.936 1.936 0 00-2.734-2.743l-3.771 3.759-3.772-3.76a1.936 1.936 0 10-2.734 2.744l3.762 3.75-3.762 3.75a1.937 1.937 0 002.734 2.743l3.772-3.76 3.772 3.76a1.93 1.93 0 001.367.565 1.937 1.937 0 001.367-3.308l-3.763-3.75z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});