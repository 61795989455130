define("@cloudcreativity/ember-quill/components/quill-editor", ["exports", "@glimmer/component", "quill"], function (_exports, _component, _quill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id={{this.id}}
    ...attributes
    {{did-insert this.initQuill}}
    {{did-update this.updateQuill enabled=@enabled delta=@delta}}
  >
  </div>
  
  */
  {
    "id": "JgO1Gjrk",
    "block": "{\"symbols\":[\"&attrs\",\"@delta\",\"@enabled\"],\"statements\":[[11,\"div\"],[16,1,[32,0,[\"id\"]]],[17,1],[4,[38,0],[[32,0,[\"initQuill\"]]],null],[4,[38,1],[[32,0,[\"updateQuill\"]]],[[\"enabled\",\"delta\"],[[32,3],[32,2]]]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill-editor.hbs"
    }
  });
  let QuillEditorComponent = _exports.default = (_dec = Ember.inject.service('quill'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class QuillEditorComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "quillService", _descriptor, this);
      _defineProperty(this, "quill", null);
      _defineProperty(this, "value", null);
    }
    get id() {
      return this.args.id ?? Ember.guidFor(this);
    }
    get name() {
      return this.args.name ?? this.id;
    }
    get modules() {
      let modules = this.args.modules ?? {};
      if (this.args._toolbar) {
        modules.toolbar = this.args._toolbar;
      }
      return modules;
    }
    initQuill(element) {
      this.quill = new _quill.default(element, {
        bounds: this.args.bounds,
        debug: this.args.debug ?? 'warn',
        formats: this.args.formats,
        modules: this.modules,
        placeholder: this.args.placeholder,
        readOnly: this.args.readOnly ?? false,
        scrollingContainer: this.args.scrollingContainer ?? null,
        theme: this.args.theme
      });
      this.quill.enable(Boolean(this.args.enabled ?? true));
      if (true === this.args.focused) {
        this.quill.focus();
      }
      if (this.args.delta) {
        this.value = this.quill.setContents(this.args.delta);
        this.doText();
      } else if (this.args.text) {
        this.value = this.quill.setText(this.args.text);
        this.doChange();
      } else {
        this.value = this.quill.getContents();
      }

      // emit the length and words on start-up
      this.doLength();
      this.doWords();

      /** Native Quill Events */
      this.quill.on('text-change', this.doTextChange);
      this.quill.on('selection-change', this.doSelectionChange);
      this.quill.on('editor-change', this.doEditorChange);

      /** Custom Events */
      this.quill.on('text-change', this.doLength);
      this.quill.on('text-change', this.doWords);
      this.quill.on('text-change', this.doChange);
      this.quill.on('text-change', this.doText);
      this.quillService.register(this.name, this.quill);
    }
    updateQuill(el, args, {
      enabled,
      delta
    }) {
      if (this.quill) {
        this.quill.enable(enabled ?? true);
        if (delta !== this.value) {
          this.value = this.quill.setContents(delta, _quill.default.sources.SILENT);
        }
      }
    }
    doTextChange(delta, oldDelta, source) {
      if (this.args.onTextChange) {
        Ember.run.schedule('actions', this, this._handleEvent, 'onTextChange', delta, oldDelta, source);
      }
    }
    doSelectionChange(range, oldRange, source) {
      if (this.args.onSelectionChange) {
        Ember.run.schedule('actions', this, this._handleEvent, 'onSelectionChange', range, oldRange, source);
      }
    }
    doEditorChange(eventName, ...args) {
      if (this.args.onEditorChange) {
        Ember.run.schedule('actions', this, this._handleEvent, 'onEditorChange', eventName, ...args);
      }
    }
    doLength() {
      if (this.quill && this.args.onLength) {
        Ember.run.schedule('actions', this, this._handleEvent, 'onLength', this.quill.getLength());
      }
    }
    doChange() {
      if (this.quill && this.args.onChange) {
        this.value = this.quill.getContents();
        Ember.run.schedule('actions', this, this._handleEvent, 'onChange', this.value);
      }
    }
    doText() {
      if (this.quill && this.args.onText) {
        Ember.run.schedule('actions', this, this._handleEvent, 'onText', this.quill.getText());
      }
    }
    doWords() {
      if (this.quill && this.args.onWords) {
        let text = this.quill.getText();
        Ember.run.schedule('actions', this, this._handleEvent, 'onWords', this._calculateWords(text));
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);

      /** Native Quill Events */
      this.quill.off('text-change', this.doTextChange);
      this.quill.off('selection-change', this.doSelectionChange);
      this.quill.off('editor-change', this.doEditorChange);

      /** Custom Events */
      this.quill.off('text-change', this.doLength);
      this.quill.off('text-change', this.doWords);
      this.quill.off('text-change', this.doChange);
      this.quill.off('text-change', this.onText);
      this.quill = null;
      this.value = null;
      this.quillService.deregister(this.name);
    }
    _handleEvent(callback, ...args) {
      if (!this.isDestroying && !this.isDestroyed && this.args[callback]) {
        this.args[callback](...args);
      }
    }
    _calculateWords(text) {
      text = text.trim();
      return text.length > 0 ? text.split(/\s+/).length : 0;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "quillService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initQuill", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initQuill"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuill", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuill"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doTextChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "doTextChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doSelectionChange", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "doSelectionChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doEditorChange", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "doEditorChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doLength", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "doLength"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doChange", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "doChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doText", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "doText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doWords", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "doWords"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, QuillEditorComponent);
});