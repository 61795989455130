define("ember-modifier/-private/functional/modifier-manager", ["exports", "ember-modifier/-private/compat"], function (_exports, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const MODIFIER_ELEMENTS = new WeakMap();
  const MODIFIER_TEARDOWNS = new WeakMap();
  function teardown(modifier) {
    const teardown = MODIFIER_TEARDOWNS.get(modifier);
    if (teardown && typeof teardown === 'function') {
      teardown();
    }
  }
  function setup(modifier, element, args) {
    const {
      positional,
      named
    } = args;
    const teardown = modifier(element, positional, named);
    MODIFIER_TEARDOWNS.set(modifier, teardown);
  }
  class FunctionalModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities(false ? '3.22' : '3.13'));
    }
    createModifier(factoryOrClass) {
      const Modifier = (0, _compat.isFactory)(factoryOrClass) ? factoryOrClass.class : factoryOrClass;

      // This looks superfluous, but this is creating a new instance
      // of a function -- this is important so that each instance of the
      // created modifier can have its own state which is stored in
      // the MODIFIER_ELEMENTS and MODIFIER_TEARDOWNS WeakMaps
      return (...args) => Modifier(...args);
    }
    installModifier(modifier, element, args) {
      MODIFIER_ELEMENTS.set(modifier, element);
      if (false) {
        (0, _compat.consumeArgs)(args);
      }
      setup(modifier, element, args);
    }
    updateModifier(modifier, args) {
      const element = MODIFIER_ELEMENTS.get(modifier);
      teardown(modifier);
      if (false) {
        (0, _compat.consumeArgs)(args);
      }
      setup(modifier, element, args);
    }
    destroyModifier(modifier) {
      teardown(modifier);
    }
  }
  var _default = _exports.default = new FunctionalModifierManager();
});