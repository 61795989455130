define("ember-svg-jar/inlined/nav.user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\">\n    <path d=\"M25.4 18h-1.1c2.1-.9 3.5-2.9 3.5-5.3 0-3.2-2.6-5.8-5.8-5.8-1.5 0-2.8.5-3.8 1.4-1-.9-2.3-1.4-3.8-1.4-3.2 0-5.8 2.6-5.8 5.8 0 2.4 1.4 4.4 3.5 5.3h-1.6c-4.5 0-8.1 3.7-8.1 8.1V29h7.9-.3 23.5v-2.9c.1-4.4-3.6-8.1-8.1-8.1zm-6.3-8.7c.8-.7 1.8-1.1 2.9-1.1 2.4 0 4.4 2 4.4 4.4S24.4 17 22 17c-1.1 0-2.1-.4-2.9-1.1.7-.9 1.1-2.1 1.1-3.4 0-1-.4-2.2-1.1-3.2zm.7 8.7H17.9 16.7c.5-.2 1-.5 1.4-.8l.2-.1c.4.4.9.7 1.5.9zm-9.7-5.3c0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4s-2 4.4-4.4 4.4c-2.5 0-4.4-2-4.4-4.4zm14.3 14.6H4v-1c0-3.6 2.9-6.5 6.5-6.5h7.4c3.6 0 6.5 2.9 6.5 6.5v1zm7.8.1H26v-1.2c0-2.6-1.2-5-3.2-6.4h2.8c3.6 0 6.6 3 6.6 6.6v1z\"/>\n    <path d=\"M5.3 26.8c-.1 0-.2-.1-.2-.2 0-1.9.5-3.4 1.6-4.5s2.8-1.6 4.9-1.6c.1 0 .2.1.2.3 0 .1-.1.2-.3.2-2 0-3.5.5-4.5 1.5s-1.5 2.4-1.5 4.1c0 .1-.1.2-.2.2z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});