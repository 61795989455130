define("ember-svg-jar/inlined/nav.impersonate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"#27CA8B\" d=\"M11.997 9.424a.5.5 0 000 .997.505.505 0 00.503-.498.505.505 0 00-.503-.499zM11.998 4.56c-1.115 0-1.498.774-1.498 1.038 0 .345.157.474.38.474.446 0 .264-.681 1.106-.681.413 0 .744.195.744.602 0 .478-.463.752-.735 1-.24.221-.554.584-.554 1.345 0 .46.042.587.38.587.195 0 .404-.038.404-.206 0-.46-.08-.839.374-1.22.223-.185.901-.674.901-1.506s-.445-1.432-1.502-1.432z\"/>\n    <path fill=\"#6D6D6D\" d=\"M7.5 6.86c0 1.169.476 2.518 1.274 3.609.906 1.238 2.052 1.92 3.226 1.92s2.32-.682 3.226-1.92c.798-1.091 1.274-2.44 1.274-3.609 0-2.38-2.019-4.316-4.5-4.316S7.5 4.48 7.5 6.86zM12 3.294c2.05 0 3.719 1.6 3.719 3.566 0 1.005-.434 2.223-1.133 3.178-.745 1.018-1.687 1.601-2.586 1.601-.899 0-1.841-.583-2.586-1.601-.698-.955-1.133-2.173-1.133-3.178 0-1.966 1.669-3.566 3.719-3.566z\"/>\n    <path fill=\"#6D6D6D\" d=\"M15.22 11.552a.375.375 0 00-.425.073c-.838.836-1.805 1.277-2.795 1.277s-1.957-.441-2.794-1.277a.375.375 0 00-.426-.073 7.516 7.516 0 00-3.089 2.717A7.473 7.473 0 004.5 18.327v.006c0 .105.045.206.123.277A10.96 10.96 0 0012 21.456a10.96 10.96 0 007.377-2.846.375.375 0 00.123-.277v-.006a7.474 7.474 0 00-1.191-4.057 7.513 7.513 0 00-3.088-2.718zM12 20.706c-2.491 0-4.881-.9-6.749-2.54a6.722 6.722 0 011.07-3.49 6.768 6.768 0 012.552-2.333c.936.857 2.013 1.309 3.127 1.309 1.114 0 2.19-.452 3.128-1.309a6.768 6.768 0 012.551 2.332 6.723 6.723 0 011.07 3.492A10.21 10.21 0 0112 20.707z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});