define("ember-svg-jar/inlined/nav.analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <rect width=\"1\" height=\"6.299\" x=\"7.757\" y=\"15.264\" fill=\"#6D6D6D\" rx=\".5\"/>\n    <rect width=\"1\" height=\"7.356\" x=\"11.568\" y=\"14.207\" fill=\"#6D6D6D\" rx=\".5\"/>\n    <rect width=\"1\" height=\"2.345\" x=\"3.994\" y=\"19.218\" fill=\"#6D6D6D\" rx=\".5\"/>\n    <rect width=\"1\" height=\"10.023\" x=\"15.33\" y=\"11.54\" fill=\"#6D6D6D\" rx=\".5\"/>\n    <rect width=\"1\" height=\"12.414\" x=\"19.141\" y=\"9.149\" fill=\"#6D6D6D\" rx=\".5\"/>\n    <path fill=\"#27CA8B\" d=\"M21.259 2.561a.55.55 0 00-.348-.124h-3.304a.473.473 0 00-.482.46c0 .252.217.46.482.46l2.132-.005-5.214 4.974-1.828-1.751a.52.52 0 00-.343-.143.52.52 0 00-.342.143L2.75 15.41a.448.448 0 00-.015.653.503.503 0 00.685.014c.005-.004.01-.01.015-.01l8.924-8.505 1.828 1.738a.516.516 0 00.685 0L20.434 4l-.005 2.028c0 .252.212.455.478.455h.004a.465.465 0 00.483-.45l.005-3.15a.461.461 0 00-.14-.322z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});