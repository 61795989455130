define("ember-svg-jar/inlined/nav.page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\">\n    <path d=\"M29.6 10.7l-7.9-7.9c-.1-.1-.3-.2-.5-.2H9.8c-2 0-3.7 1.6-3.7 3.7v23.5c0 2 1.6 3.7 3.7 3.7h16.4c2 0 3.7-1.6 3.7-3.7V11.2c0-.2-.1-.4-.3-.5zm-7.7-5.6l5.5 5.4h-3.2c-1.2 0-2.2-1-2.2-2.2V5.1zm6.5 24.7c0 1.2-1 2.2-2.2 2.2H9.8c-1.2 0-2.2-1-2.2-2.2V6.2C7.6 5 8.6 4 9.8 4h10.6v4.3c0 2 1.6 3.7 3.7 3.7h4.3v17.8z\"/>\n    <path d=\"M11.5 21h13c.3 0 .5.2.5.5s-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5s.2-.5.5-.5zM11.5 16.8h13c.3 0 .5.2.5.5s-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5s.2-.5.5-.5zM11.5 25.2h13c.3 0 .5.2.5.5s-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5s.2-.5.5-.5z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 36 36"
    }
  };
});