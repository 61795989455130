define("ember-svg-jar/inlined/grad.siemens.burn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <title>icons/grad.burn</title>\n    <defs>\n        <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"linearGradient-1\">\n            <stop stop-color=\"#F57E8E\" offset=\"0%\"/>\n            <stop stop-color=\"#797696\" offset=\"100%\"/>\n        </linearGradient>\n    </defs>\n    <g id=\"--Icons\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n        <g id=\"icons/grad.burn\">\n            <path d=\"M13.9484327,19.4209464 C17.0145038,16.3562429 22.3370501,10.0764041 20.4293029,4.99044579 C20.4293029,4.99044579 27.3860842,12.1988583 25.4140617,21.4654497 C25.4140617,21.4654497 29.7519642,18.183304 29.9817144,12.7896445 C33.4183945,16.7665111 33.5866045,26.0645564 33.4006162,28.1692323 C32.822138,34.6774537 28.102686,40 21.5698485,40 C15.0358959,40 9.7390808,34.7031849 9.7390808,28.1692323 C9.7622196,27.4373176 9.87801714,26.7112854 10.0837061,26.0084864 C10.7745953,23.5128313 12.1070431,21.2416349 13.9484327,19.4209464 L13.9484327,19.4209464 Z\" id=\"Path-Copy\" fill=\"#DEDEDE\"/>\n            <path d=\"M11.7603355,15.919991 C14.8264066,12.8552874 20.1489529,6.57544866 18.2412057,1.48949037 C18.2412057,1.48949037 25.1979871,8.69790288 23.2259645,17.9644943 C23.2259645,17.9644943 27.5638671,14.6823486 27.7936173,9.28868911 C31.2302974,13.2655557 31.3985073,22.5636009 31.2125191,24.6682769 C30.6340409,31.1764983 25.9145889,36.4990446 19.3817514,36.4990446 C12.8477988,36.4990446 7.55098366,31.2022295 7.55098366,24.6682769 C7.57412246,23.9363622 7.68992,23.21033 7.89560896,22.5075309 C8.58649815,20.0118758 9.91894592,17.7406795 11.7603355,15.919991 L11.7603355,15.919991 Z\" id=\"Path-Copy-2\" stroke=\"url(#linearGradient-1)\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n        </g>\n    </g>\n",
    "attrs": {
      "width": "40px",
      "height": "40px",
      "viewBox": "0 0 40 40",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});