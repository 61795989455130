define("ember-svg-jar/inlined/melon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M15.603 67.91c14.642 10.187 34.972 8.757 48.018-4.289s14.475-33.376 4.29-48.018L15.602 67.91z\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M82.94.572a1.954 1.954 0 00-2.761 0L40.375 40.375.572 80.18a1.953 1.953 0 000 2.762C11.572 93.94 26.2 100 41.756 100c15.558 0 30.184-6.058 41.185-17.06 11-11 17.059-25.626 17.059-41.184C100 26.2 93.941 11.573 82.94.572zM71.399 71.397c-7.905 7.905-18.431 12.258-29.642 12.258-10.554 0-20.503-3.86-28.228-10.908l59.219-59.219c14.982 16.43 14.532 41.988-1.35 57.87zm8.78 8.782c-10.594 10.594-24.505 15.89-38.422 15.89-13.306-.002-26.615-4.845-37.016-14.534l6.025-6.025c8.464 7.785 19.393 12.051 30.991 12.05 12.254 0 23.762-4.758 32.404-13.4 17.404-17.405 17.852-45.44 1.35-63.395l6.026-6.025c19.82 21.278 19.367 54.714-1.357 75.439z\"/>\n      <path d=\"M41.543 54.345a1.953 1.953 0 00-2.762 2.762l1.344 1.344c.381.382.881.572 1.38.572a1.953 1.953 0 001.381-3.334l-1.343-1.344zM50.67 50.67c.762-.764.762-2 0-2.763l-1.345-1.344a1.953 1.953 0 00-2.762 2.762l1.344 1.344c.381.382.881.572 1.381.572s1-.19 1.381-.572zM58.451 42.887c.763-.763.763-2 0-2.762l-1.344-1.344a1.953 1.953 0 00-2.762 2.762l1.344 1.344c.381.382.881.572 1.38.572.5 0 1-.19 1.382-.572zM51.674 56.694a1.954 1.954 0 00-2.762 2.762l1.344 1.345c.382.381.882.572 1.381.572a1.953 1.953 0 001.381-3.334l-1.344-1.345zM59.456 48.912a1.953 1.953 0 00-2.762 2.762l1.344 1.344c.382.382.882.572 1.381.572a1.953 1.953 0 001.381-3.334l-1.344-1.344z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});