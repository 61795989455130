define("ember-svg-jar/inlined/grad.tommy.body-fat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"98.753%\" id=\"body-fat-a\">\n      <stop stop-color=\"#24BABC\" offset=\"0%\"/>\n      <stop stop-color=\"#B87CE2\" offset=\"100%\"/>\n    </linearGradient>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"body-fat-b\">\n      <stop stop-color=\"#2ABCBE\" offset=\"0%\"/>\n      <stop stop-color=\"#B87CE2\" offset=\"100%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <ellipse fill=\"currentColor\" cx=\"25.951\" cy=\"21\" rx=\"14.049\" ry=\"19\"/>\n    <path d=\"M25.379 33.205V19.618c.02-.436.035-.875.035-1.32 0-6.15-2.008-11.5-5.15-14.801h.955c.806.078 8.629 1.144 9.417 12.85h-3.333v3.497h3.402v1.915h-3.402v3.497h3.402v1.914h-3.402v3.496h3.402v2.539H25.38ZM12.707 33.1c-4.992 0-9.21-6.779-9.21-14.802s4.218-14.801 9.21-14.801c4.936 0 9.112 6.627 9.206 14.532h-.03v1.479C21.484 26.99 17.445 33.1 12.706 33.1ZM21.42.005 12.712 0h-.368v.013C5.394.281 0 8.213 0 18.298c0 10.26 5.582 18.299 12.707 18.299 3.643 0 6.879-2.104 9.175-5.529v5.633h12.32V18.298C34.202 1.14 21.549.014 21.422.005Z\" fill=\"url(#body-fat-a)\"/>\n    <path d=\"M12.734 25.16c-.49-.102-2.031-2.356-2.031-6.85 0-4.495 1.541-6.75 2.012-6.851.49.101 2.032 2.356 2.032 6.85 0 4.495-1.542 6.75-2.013 6.85m-.01-17.196c-3.622 0-5.518 5.205-5.518 10.346 0 5.142 1.896 10.347 5.518 10.347 3.623 0 5.52-5.205 5.52-10.347 0-5.141-1.897-10.346-5.52-10.346\" fill=\"url(#body-fat-b)\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});