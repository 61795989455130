define("ember-svg-jar/inlined/tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M83.863 44.08c-.655-.846-1.87-1-2.716-.346-.276.214-6.837 5.34-13.466 14.48-3.896 5.374-7.003 11.027-9.233 16.801-2.557 6.62-3.963 13.416-4.187 20.228h-9.247c-.223-6.843-1.629-13.658-4.187-20.287-2.231-5.782-5.338-11.433-9.235-16.796-6.63-9.126-13.194-14.217-13.47-14.43a1.936 1.936 0 00-2.36 3.07c.064.048 6.393 4.959 12.698 13.636 5.8 7.982 12.714 20.837 12.714 36.743 0 1.07.866 1.936 1.936 1.936h13.055a1.936 1.936 0 001.935-1.936c0-15.786 6.876-28.613 12.644-36.593 6.306-8.725 12.71-13.741 12.773-13.79.845-.655 1-1.87.346-2.716z\"/>\n      <path d=\"M41.583 64.647a1.936 1.936 0 003.61-.972v-18.41a1.936 1.936 0 00-3.871 0v11.22l-7.078-12.192a1.936 1.936 0 00-3.349 1.944l10.688 18.41zM55.514 65.545a1.936 1.936 0 002.177-.897L68.38 46.237a1.936 1.936 0 00-3.348-1.944l-7.078 12.192v-11.22a1.936 1.936 0 00-3.872 0v18.41c0 .876.588 1.642 1.433 1.87z\"/>\n    </g>\n    <path fill=\"currentColor\" d=\"M79.74 16.56C79.44 7.366 71.675 0 62.139 0c-4.887 0-9.31 1.935-12.5 5.06C46.445 1.935 42.023 0 37.135 0 27.6 0 19.834 7.365 19.533 16.56c-7.987 1.625-13.987 8.508-13.987 16.752 0 9.452 7.885 17.115 17.612 17.115 5.277 0 10.012-2.255 13.24-5.828 3.227 3.572 7.962 5.828 13.24 5.828 5.276 0 10.01-2.255 13.239-5.828 3.228 3.572 7.962 5.828 13.24 5.828 9.726 0 17.611-7.663 17.611-17.115 0-8.244-6-15.127-13.987-16.751z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});