define("ember-svg-jar/inlined/smoothie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M38.547 29.98h43.11l-6.198 63.265H42.873z\"/>\n    <path stroke=\"currentColor\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M26.368 8.52h47.264l-7.613 82.96H32.538z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});