define("ember-svg-jar/inlined/ping-pong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M29.09 57.434a27.922 27.922 0 01-2.17-2.738c-3.256-4.678-4.976-10.183-4.976-15.918 0-15.351 12.439-27.84 27.728-27.84 15.29 0 27.728 12.489 27.728 27.84 0 5.735-1.72 11.24-4.975 15.918-.665.956-1.39 1.87-2.172 2.738H29.09z\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M49.672 0c-21.32 0-38.666 17.396-38.666 38.778 0 7.98 2.398 15.643 6.935 22.164a38.992 38.992 0 006.18 6.94c2.946 2.603 11.52 9.45 17.754 11.741-.1 1.195-.522 3.748-1.665 8.333-.857 3.441.181 7.04 2.71 9.39A9.886 9.886 0 0049.673 100c2.512 0 4.91-.942 6.751-2.654 2.53-2.35 3.568-5.949 2.71-9.39-1.142-4.584-1.564-7.138-1.664-8.333 6.234-2.29 14.81-9.14 17.754-11.742a38.99 38.99 0 006.18-6.938c4.537-6.521 6.934-14.185 6.934-22.164C88.337 17.396 70.992 0 49.672 0zm5.571 76.245c-1.2.34-1.77 1.43-1.691 3.235.033.765.16 1.76.387 3.039.385 2.163.976 4.664 1.405 6.382.513 2.062-.092 4.201-1.58 5.584a5.995 5.995 0 01-4.092 1.609 5.994 5.994 0 01-4.092-1.609c-1.488-1.383-2.093-3.522-1.58-5.584.428-1.718 1.02-4.22 1.404-6.382.228-1.28.355-2.274.388-3.04.078-1.805-.49-2.893-1.692-3.234-3.449-.978-8.852-4.521-13.22-7.874h37.583c-4.367 3.353-9.77 6.896-13.22 7.874zm22.953-17.533a35.059 35.059 0 01-5.017 5.753H26.165a35.06 35.06 0 01-5.018-5.753c-4.078-5.863-6.234-12.756-6.234-19.934 0-19.228 15.593-34.872 34.759-34.872s34.76 15.644 34.76 34.872c0 7.178-2.157 14.07-6.236 19.934z\"/>\n      <path d=\"M59.877 26.048c-5.789 0-10.498 4.709-10.498 10.497 0 5.789 4.71 10.498 10.498 10.498 5.788 0 10.497-4.71 10.497-10.498 0-5.788-4.709-10.497-10.497-10.497zm0 17.088a6.599 6.599 0 01-6.591-6.59 6.599 6.599 0 016.59-6.592 6.599 6.599 0 016.592 6.591 6.599 6.599 0 01-6.591 6.591z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});