define("ember-svg-jar/inlined/stopwatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M83.043 56.05a33.198 33.198 0 01-5.297 18.032L49.641 56.051V22.648c18.418 0 33.402 14.985 33.402 33.403z\"/>\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M87.546 33.778l2.108-2.108a6.607 6.607 0 000-9.333l-6.97-6.97a6.556 6.556 0 00-4.666-1.932c-1.763 0-3.42.686-4.666 1.932l-2.289 2.288a43.394 43.394 0 00-9.596-3.953v-1.875C61.467 5.305 56.162 0 49.641 0c-6.522 0-11.827 5.305-11.827 11.827v1.876a43.394 43.394 0 00-9.596 3.953l-2.288-2.288a6.555 6.555 0 00-4.667-1.933c-1.763 0-3.42.686-4.666 1.933l-6.97 6.97a6.607 6.607 0 000 9.332l2.108 2.108c-3.94 6.68-6.044 14.314-6.044 22.273 0 11.739 4.572 22.776 12.873 31.077C26.864 95.428 37.9 100 49.64 100c11.739 0 22.776-4.571 31.077-12.872 8.3-8.301 12.872-19.338 12.872-31.077 0-7.96-2.104-15.594-6.044-22.273zM76.114 18.13a2.675 2.675 0 011.904-.789c.72 0 1.396.28 1.904.789l6.97 6.97c.509.508.789 1.185.789 1.904 0 .72-.28 1.396-.789 1.904l-1.529 1.529A44.51 44.51 0 0074.47 19.775l1.645-1.645zM41.72 11.827c0-4.368 3.553-7.92 7.92-7.92 4.368 0 7.921 3.552 7.921 7.92v.985a44.443 44.443 0 00-15.84 0v-.985zM12.39 28.908a2.676 2.676 0 01-.79-1.904c0-.72.28-1.396.79-1.905l6.969-6.97a2.675 2.675 0 011.904-.788c.72 0 1.396.28 1.904.789l1.646 1.645a44.415 44.415 0 00-10.895 10.662l-1.529-1.529zm37.25 67.186c-22.08 0-40.043-17.964-40.043-40.043 0-22.08 17.964-40.044 40.044-40.044s40.043 17.964 40.043 40.044-17.963 40.043-40.043 40.043z\"/>\n      <path d=\"M49.64 20.695c-19.494 0-35.355 15.86-35.355 35.356 0 19.495 15.86 35.355 35.356 35.355 12.07 0 23.191-6.081 29.747-16.267a35.184 35.184 0 005.608-19.088c0-19.495-15.86-35.356-35.355-35.356zm26.464 52.33C70.27 82.089 60.377 87.5 49.64 87.5c-17.341 0-31.45-14.108-31.45-31.45 0-17.34 14.109-31.448 31.45-31.448 17.34 0 31.449 14.108 31.449 31.449 0 6.039-1.724 11.908-4.986 16.974z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});