define("ember-svg-jar/inlined/fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" transform=\"translate(0 32.591)\">\n      <path d=\"M18.912 17.409a46.473 46.473 0 015.552-8.335C22.162 3.76 16.898.044 10.772.044H0v2.33c0 8.303 3.184 15.035 11.432 15.035C3.184 17.409 0 24.14 0 32.444v2.329h10.772c6.126 0 11.39-3.715 13.692-9.03a46.463 46.463 0 01-5.552-8.334z\"/>\n      <ellipse cx=\"48.682\" cy=\"17.451\" rx=\"11.639\" ry=\"6.886\"/>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M78.987 42.436a1.934 1.934 0 00-1.934 1.934v4.374a1.934 1.934 0 003.869 0V44.37a1.934 1.934 0 00-1.935-1.934z\"/>\n    <path fill=\"#A7A8AA\" d=\"M98.239 50.042l.236-.238a1.934 1.934 0 00.308-2.32c-4.055-7.118-9.85-13.085-16.757-17.255a45.656 45.656 0 00-23.593-6.579c-17.267 0-33.068 9.755-41.237 25.457a1.934 1.934 0 000 1.785c8.17 15.703 23.97 25.458 41.236 25.458A45.66 45.66 0 0082 69.787c6.901-4.16 12.694-10.113 16.752-17.216a1.935 1.935 0 00-.307-2.323l-.205-.206zM21.105 50c7.627-13.893 21.839-22.481 37.328-22.481 4.32 0 8.563.684 12.6 1.976-2.745 6.472-4.136 13.363-4.136 20.502 0 7.142 1.391 14.034 4.137 20.507a41.313 41.313 0 01-12.602 1.977c-15.488 0-29.7-8.588-37.327-22.481zm73.035 1.405l.504.507c-4.818 7.82-11.867 13.796-19.98 17.242-2.587-6.04-3.898-12.479-3.898-19.157 0-6.675 1.311-13.113 3.897-19.152 8.132 3.453 15.193 9.447 20.013 17.294l-.536.54a1.934 1.934 0 000 2.726z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});