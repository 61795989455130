define("ember-stopwatch/utils/stopwatch", ["exports", "ember-stopwatch/utils/evented"], function (_exports, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_TICK_MILLIS = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const DEFAULT_TICK_MILLIS = _exports.DEFAULT_TICK_MILLIS = 100;

  /**
   * Supported subscription events are: tick, start, stop, reset
   */
  let Stopwatch = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class Stopwatch extends _evented.default {
    constructor(tickMillis = DEFAULT_TICK_MILLIS) {
      super();
      _initializerDefineProperty(this, "elapsedMillis", _descriptor, this);
      _initializerDefineProperty(this, "systemElapsedMillis", _descriptor2, this);
      _initializerDefineProperty(this, "numTicks", _descriptor3, this);
      _initializerDefineProperty(this, "intervalId", _descriptor4, this);
      this.tickMillis = Math.max(0, tickMillis || DEFAULT_TICK_MILLIS);
    }

    // use skipCheck in modifiers, to avoid "used previously in same computation" error
    start(skipCheck = false) {
      if (skipCheck || !this.isRunning) {
        this.startTime = Date.now();
        this.intervalId = setInterval(() => {
          this._tick();
        }, this.tickMillis);
        this.trigger('start', this);
      }
    }
    get isRunning() {
      return this.intervalId !== undefined;
    }
    get resolutionMillis() {
      return this.tickMillis;
    }
    reset(force = false) {
      if (this.isRunning && !force) {
        // mark we want to reset it on the next tick
        this.resetSentinel = true;
      } else {
        this._forceReset();
      }
    }
    stop(force = false) {
      if (this.isRunning && !force) {
        // stop on the next tick
        this.stopSentinel = true;
      } else {
        this._forceStop();
      }
    }
    get variance() {
      return this.systemElapsedMillis - this.elapsedMillis;
    }
    _tick() {
      this.elapsedMillis += this.tickMillis;
      this.systemElapsedMillis = Date.now() - this.startTime + (this.cachedSystemMillis || 0);
      this.numTicks += 1;
      this.trigger('tick', this);
      this._checkSentinels();
    }
    _checkSentinels() {
      if (this.stopSentinel || this.resetSentinel) {
        if (this.resetSentinel) {
          this._forceReset();
        } else {
          this._forceStop();
        }
      }
    }
    _forceStop() {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
      this.cachedSystemMillis = this.systemElapsedMillis;
      this.stopSentinel = false;
      this.trigger('stop', this);
    }
    _forceReset() {
      this._forceStop();
      this.numTicks = 0;
      this.elapsedMillis = 0;
      this.systemElapsedMillis = 0;
      this.startTime = undefined;
      this.cachedSystemMillis = 0;
      this.resetSentinel = false;
      this.trigger('reset', this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "elapsedMillis", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "systemElapsedMillis", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "numTicks", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intervalId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});