define("ember-svg-jar/inlined/grad.tommy.steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"steps-a\">\n      <stop stop-color=\"#24BBBC\" offset=\"0%\"/>\n      <stop stop-color=\"#BA7EE2\" offset=\"100%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path d=\"M33.416 3.554C37.052 3.554 40 8.409 40 14.397c0 3.332-.824 6.867-1.646 12.322-.644 4.274-2.21 7.816-4.938 7.816-2.726 0-4.554-2.361-4.554-6.678 0-1.47 1.209-3.813 1.263-5.811.097-3.605-2.417-5.043-2.417-8.375 0-5.988 2.073-10.117 5.708-10.117ZM10.282 9.018c3.636 0 5.71 4.13 5.71 10.118 0 3.332-2.516 4.77-2.418 8.375.054 1.998 1.263 4.34 1.263 5.81 0 4.317-1.828 6.679-4.555 6.679-2.726 0-4.293-3.542-4.937-7.816-.822-5.456-1.646-8.99-1.646-12.322 0-5.989 2.947-10.844 6.583-10.844Z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>\n    <path d=\"M31.76 1.669c-3.636 0-6.584 4.855-6.584 10.844 0 3.332.824 6.866 1.646 12.322.644 4.274 2.21 7.816 4.938 7.816 2.727 0 4.554-2.362 4.554-6.68 0-1.47-1.209-3.812-1.262-5.81-.098-3.604 2.417-5.043 2.417-8.375 0-5.988-2.074-10.117-5.71-10.117Z\" stroke=\"url(#steps-a)\" stroke-width=\"2.5\" transform=\"matrix(-1 0 0 1 62.645 0)\"/>\n    <path d=\"M6.876 6.258c-3.635 0-5.709 4.129-5.709 10.117 0 3.332 2.515 4.77 2.418 8.375-.054 1.998-1.263 4.34-1.263 5.81 0 4.317 1.827 6.68 4.554 6.68 2.727 0 4.294-3.543 4.938-7.817.822-5.455 1.646-8.99 1.646-12.322 0-5.988-2.948-10.843-6.584-10.843Z\" stroke=\"url(#steps-a)\" stroke-width=\"2.5\" transform=\"matrix(-1 0 0 1 14.627 0)\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});