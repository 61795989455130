define("ember-svg-jar/inlined/no-fast-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M93.339 31.292C92.82 18.73 82.44 8.667 69.752 8.667H30.239c-12.689 0-23.07 10.062-23.587 22.625C2.89 31.867 0 35.123 0 39.042v.045C0 43 2.88 46.25 6.63 46.833v5.686c0 7.328 5.961 13.289 13.288 13.289H37.56v-3.906h-17.64c-5.174 0-9.383-4.21-9.383-9.383v-5.592h78.92v5.592c0 5.174-4.21 9.383-9.384 9.383h-18.08v3.906h18.08c7.328 0 13.289-5.961 13.289-13.289v-5.686c3.75-.583 6.63-3.835 6.63-7.746v-.045c0-3.919-2.891-7.175-6.652-7.75zm-63.1-18.72h15.28l-3.169 3.194a1.953 1.953 0 102.773 2.752l4.872-4.91 4.873 4.91a1.953 1.953 0 002.772-2.752l-3.17-3.194h15.282c10.504 0 19.115 8.263 19.674 18.63H10.564c.56-10.367 9.17-18.63 19.675-18.63zm-22.4 30.449a3.938 3.938 0 01-3.933-3.934v-.045a3.938 3.938 0 013.934-3.934H28.59l6.95 7.913H7.84zm25.951-7.913h12.922l-6.784 6.988-6.138-6.988zm10.684 7.913l5.383-5.544 4.87 5.544H44.474zm8.501-7.913h12.923l-6.785 6.988-6.138-6.988zm43.11 3.979a3.938 3.938 0 01-3.934 3.934H63.659l7.682-7.913h20.81a3.938 3.938 0 013.934 3.934v.045z\"/>\n    <g transform=\"translate(30.466 52.246)\">\n      <circle cx=\"19.529\" cy=\"19.452\" r=\"19.44\" fill=\"currentColor\"/>\n      <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M22.291 19.513l3.452-3.452a1.953 1.953 0 00-2.761-2.762l-3.453 3.452-3.452-3.452a1.954 1.954 0 00-2.762 2.762l3.452 3.452-3.452 3.452a1.953 1.953 0 002.762 2.762l3.452-3.452 3.453 3.452c.381.381.88.572 1.38.572a1.953 1.953 0 001.38-3.334l-3.45-3.452z\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});