define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <g id=\"image\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n        <g id=\"Group\" transform=\"translate(2.000000, 5.000000)\" fill=\"currentColor\" fill-rule=\"nonzero\">\n            <polygon id=\"Shape\" points=\"16 10.6666667 0 10.6666667 5.33333333 -1.51286391e-13 9.33333333 8 12 2.66666667\"/>\n        </g>\n    </g>\n",
    "attrs": {
      "viewBox": "0 0 20 20"
    }
  };
});