define("ember-svg-jar/inlined/wrap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <rect width=\"37.5\" height=\"57\" x=\"22.966\" y=\"27.5\" fill=\"currentColor\" fill-opacity=\"0.5\" rx=\"4\"/>\n    <rect width=\"36.932\" height=\"68.337\" x=\"31.534\" y=\"21.832\" stroke=\"currentColor\" stroke-width=\"4\" rx=\"10\"/>\n    <path stroke=\"currentColor\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M34.0606842 25.0417786c18.6918233 19.4854838 18.6918233 40.3851725 0 62.6990661M64.47181299 87.85778813c-18.34890786-19.80873336-17.984158-40.70523894 1.09424958-62.68951673\"/>\n    <g stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"5\">\n      <path d=\"M57.160192 14.622c.1512664-4.69810486-2.1212038-7.04715729-6.8174109-7.04715729-4.696207 0-7.0632662 2.34905243-7.1011775 7.04715729M42.1601742 14.62553888c-2.88917945-3.74472138-6.15858835-4.16400614-9.80822668-1.25785428-3.64963832 2.90615187-3.98582099 6.1965147-1.008548 9.8710886m26.29945678-8.61323432c2.8891795-3.74472138 6.1585884-4.16400614 9.8082267-1.25785428 3.6496383 2.90615187 3.985821 6.1965147 1.008548 9.8710886\"/>\n    </g>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});