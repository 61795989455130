define("ember-svg-jar/inlined/health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" d=\"M81.974 13.67c-6.164 0-10.999 5.13-10.999 5.13s-4.559-5.13-10.999-5.13c-6.264 0-11.378 5.772-11.713 12.76-.189 3.947.95 6.95 2.555 9.672 3.207 5.437 20.177 16.464 20.177 16.464s16.908-10.98 20.136-16.464c1.609-2.733 2.744-5.725 2.555-9.672-.334-6.988-5.448-12.76-11.712-12.76\"/>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M97.308 61.57c-2.462-3.38-7.134-4.275-10.634-2.038l-10.896 6.961c0-.97-.113-2.048-.44-3.123-.692-2.277-2.9-6.097-9.904-6.097h-5.515c-3.97 0-7.877-.917-11.61-2.726-7.163-3.47-13.633-4.022-17.75-1.517-3.624 2.205-6.416 4.95-7.598 6.2h-1.908v-.43a1.931 1.931 0 00-1.931-1.93H1.932A1.931 1.931 0 000 58.8v20.978c0 1.067.865 1.932 1.931 1.932h17.191a1.931 1.931 0 001.931-1.932v-3.22c4.041-.919 10.613-1.532 12.023 1.28 1.532 3.056 5.45 5.36 11.643 6.847 4.504 1.082 10.076 1.646 15.653 1.646 9.169 0 18.35-1.526 22.812-4.792l12.192-8.409c1.81-1.247 3.011-3.142 3.384-5.334a8.16 8.16 0 00-1.452-6.225zM17.191 77.848H3.862V60.732h13.329v17.115zm77.762-10.7a4.23 4.23 0 01-1.77 2.804l-12.215 8.424a1.704 1.704 0 00-.049.035c-5.578 4.107-23.392 5.379-35.298 2.52-4.822-1.158-8.136-2.916-9.093-4.824-1.65-3.288-5.498-4.189-9.223-4.189-2.32 0-4.59.35-6.252.69v-9.515h2.758c.562 0 1.096-.244 1.462-.67.03-.034 3.058-3.516 7.293-6.093 2.932-1.783 8.187-1.15 14.058 1.694 4.263 2.065 8.736 3.112 13.295 3.112h5.515c2.831 0 4.747.748 5.693 2.223 1.027 1.6.855 3.89.569 5.229h-16.27a1.931 1.931 0 000 3.862h17.64c.678-.006 1.024-.293 1.04-.303l14.647-9.36c1.814-1.158 4.15-.703 5.433 1.06.695.953.967 2.126.767 3.302z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});