define("@cloudcreativity/ember-quill/components/quill/toolbar/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="ql-formats" ...attributes>
    {{yield}}
  </span>
  
  */
  {
    "id": "TOC8RTKS",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"span\"],[24,0,\"ql-formats\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/group.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});