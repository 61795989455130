define("ember-svg-jar/inlined/medical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M62.172 100H37.828c-4.814 0-8.73-3.917-8.73-8.73V70.902H8.73C3.917 70.903 0 66.986 0 62.172V37.828c0-4.814 3.917-8.73 8.73-8.73h20.367V8.73C29.097 3.917 33.014 0 37.828 0h24.344c4.814 0 8.73 3.917 8.73 8.73v20.367H91.27c4.814 0 8.731 3.917 8.731 8.731v24.344c0 4.814-3.917 8.73-8.73 8.73H70.902V91.27c0 4.814-3.917 8.731-8.731 8.731zM8.73 33.004a4.83 4.83 0 00-4.825 4.824v24.344a4.83 4.83 0 004.825 4.824h22.32c1.078 0 1.953.875 1.953 1.954v22.32a4.83 4.83 0 004.824 4.824h24.344a4.83 4.83 0 004.824-4.825V68.95c0-1.078.875-1.953 1.953-1.953h22.32a4.83 4.83 0 004.825-4.824V37.828a4.83 4.83 0 00-4.825-4.824h-22.32a1.953 1.953 0 01-1.953-1.953V8.73a4.83 4.83 0 00-4.824-4.825H37.828a4.83 4.83 0 00-4.824 4.825v22.32a1.953 1.953 0 01-1.953 1.953H8.73z\"/>\n    <path fill=\"currentColor\" d=\"M40.035 89.063V59.965H10.938v-19.93h29.097V10.938h19.93v29.097h29.097v19.93H59.965v29.097z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});