define("ember-svg-jar/inlined/grad.tommy.blood-pressure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <defs>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"blood-pressure-a\">\n      <stop stop-color=\"#35BEC1\" offset=\"0%\"/>\n      <stop stop-color=\"#B77CE2\" offset=\"100%\"/>\n    </linearGradient>\n    <linearGradient x1=\"50%\" y1=\"0%\" x2=\"50%\" y2=\"100%\" id=\"blood-pressure-b\">\n      <stop stop-color=\"#35BEC1\" offset=\"0%\"/>\n      <stop stop-color=\"#B77CE2\" offset=\"100%\"/>\n    </linearGradient>\n  </defs>\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path d=\"M5.428 22.714c0-9.547 7.74-17.287 17.286-17.287C32.261 5.427 40 13.167 40 22.714 40 32.26 32.26 40 22.714 40c-9.547 0-17.286-7.74-17.286-17.286\" fill=\"currentColor\"/>\n    <path d=\"M0 18.186C0 8.142 8.142 0 18.186 0s18.186 8.142 18.186 18.186-8.142 18.187-18.186 18.187S0 28.23 0 18.186Z\" stroke=\"url(#blood-pressure-a)\" stroke-width=\"3\" transform=\"translate(1.484 1.579)\"/>\n    <path d=\"M4.686 15.646c-.835 0-1.515-.68-1.515-1.514a1.516 1.516 0 0 1 3.028 0c0 .835-.678 1.514-1.513 1.514M6.27 9.729V1.586a1.586 1.586 0 1 0-3.171 0v8.143A4.688 4.688 0 0 0 0 14.132a4.69 4.69 0 0 0 4.686 4.685 4.69 4.69 0 0 0 4.685-4.685 4.688 4.688 0 0 0-3.1-4.403\" transform=\"translate(15.314 6.175)\" fill=\"url(#blood-pressure-b)\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 40 40"
    }
  };
});