define("ember-svg-jar/inlined/bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\">\n      <path d=\"M18.096 43.083h25.229v16.976h-25.23zM54.942 43.083h25.229v16.976H54.942z\"/>\n      <g>\n        <path d=\"M75.004 87.688h14.963V96.4H75.004zM8.3 87.688h14.962V96.4H8.3z\"/>\n      </g>\n    </g>\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M97.308 75.33v-8.127c0-3.56-2.055-6.646-5.04-8.142v-17.05c0-5.766-4.69-10.457-10.455-10.457H16.454c-5.765 0-10.456 4.691-10.456 10.457v17.05c-2.985 1.496-5.04 4.583-5.04 8.142v8.126A1.958 1.958 0 00-1 77.288v10.4c0 1.08.877 1.958 1.958 1.958h96.35a1.958 1.958 0 001.959-1.959v-10.4a1.958 1.958 0 00-1.959-1.958zM9.915 58.1V42.01a6.547 6.547 0 016.54-6.539h65.358a6.547 6.547 0 016.54 6.54V58.1H9.914zm-5.04 9.103a5.192 5.192 0 015.186-5.186h78.144a5.192 5.192 0 015.187 5.186v8.126H4.875v-8.126zM95.35 85.73H2.917v-6.483H95.35v6.483zM35.684 25.83a1.958 1.958 0 001.721 1.026h7.47a1.958 1.958 0 000-3.917h-3.856l5.323-8.169a1.958 1.958 0 00-1.641-3.027h-7.066a1.958 1.958 0 000 3.916h3.452l-5.322 8.17a1.958 1.958 0 00-.081 2.002zM48.213 20.655a1.958 1.958 0 001.734 1.048H60.86a1.958 1.958 0 000-3.917h-7.185L62.032 5.67A1.959 1.959 0 0060.42 2.6H50.223a1.958 1.958 0 000 3.917h6.468l-8.356 12.116c-.413.599-.46 1.378-.122 2.022z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});