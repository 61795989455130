define("@cloudcreativity/ember-quill/components/quill/toolbar/select", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <select class={{this.qlClass}} ...attributes>
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{#each @values as |value|}}
        <option selected={{eq @selected value}} value={{if value value}}></option>
      {{/each}}
    {{/if}}
  </select>
  
  */
  {
    "id": "M0Jtp5gR",
    "block": "{\"symbols\":[\"value\",\"@selected\",\"@values\",\"&default\",\"&attrs\"],\"statements\":[[11,\"select\"],[16,0,[32,0,[\"qlClass\"]]],[17,5],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"option\"],[15,\"selected\",[30,[36,0],[[32,2],[32,1]],null]],[15,2,[30,[36,1],[[32,1],[32,1]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/select.hbs"
    }
  });
  class QuillToolbarSelectComponent extends _component.default {
    get qlClass() {
      if (this.args.type) {
        return `ql-${this.args.type}`;
      }
      return null;
    }
  }
  _exports.default = QuillToolbarSelectComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, QuillToolbarSelectComponent);
});