define("@cloudcreativity/ember-quill/components/quill/toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes id={{@id}}>
    {{yield (hash
      align=(component "quill/toolbar/button-or-select" type="align")
      background=(component "quill/toolbar/select" type="background")
      blockquote=(component "quill/toolbar/button" type="blockquote")
      bold=(component "quill/toolbar/button" type="bold")
      clean=(component "quill/toolbar/button" type="clean")
      code-block=(component "quill/toolbar/button" type="code-block")
      code=(component "quill/toolbar/button" type="code")
      color=(component "quill/toolbar/select" type="color")
      direction=(component "quill/toolbar/button" type="direction")
      font=(component "quill/toolbar/select" type="font")
      formula=(component "quill/toolbar/button" type="formula")
      group=(component "quill/toolbar/group")
      header=(component "quill/toolbar/button-or-select" type="header")
      image=(component "quill/toolbar/button" type="image")
      indent=(component "quill/toolbar/button" type="indent")
      italic=(component "quill/toolbar/button" type="italic")
      link=(component "quill/toolbar/button" type="link")
      list=(component "quill/toolbar/button" type="list")
      script=(component "quill/toolbar/button" type="script")
      size=(component "quill/toolbar/select" type="size")
      strike=(component "quill/toolbar/button" type="strike")
      underline=(component "quill/toolbar/button" type="underline")
      video=(component "quill/toolbar/button" type="video")
    )}}
  </div>
  
  */
  {
    "id": "lLJ5GDU2",
    "block": "{\"symbols\":[\"&attrs\",\"@id\",\"&default\"],\"statements\":[[11,\"div\"],[17,1],[16,1,[32,2]],[12],[2,\"\\n  \"],[18,3,[[30,[36,1],null,[[\"align\",\"background\",\"blockquote\",\"bold\",\"clean\",\"code-block\",\"code\",\"color\",\"direction\",\"font\",\"formula\",\"group\",\"header\",\"image\",\"indent\",\"italic\",\"link\",\"list\",\"script\",\"size\",\"strike\",\"underline\",\"video\"],[[30,[36,0],[\"quill/toolbar/button-or-select\"],[[\"type\"],[\"align\"]]],[30,[36,0],[\"quill/toolbar/select\"],[[\"type\"],[\"background\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"blockquote\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"bold\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"clean\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"code-block\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"code\"]]],[30,[36,0],[\"quill/toolbar/select\"],[[\"type\"],[\"color\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"direction\"]]],[30,[36,0],[\"quill/toolbar/select\"],[[\"type\"],[\"font\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"formula\"]]],[30,[36,0],[\"quill/toolbar/group\"],null],[30,[36,0],[\"quill/toolbar/button-or-select\"],[[\"type\"],[\"header\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"image\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"indent\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"italic\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"link\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"list\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"script\"]]],[30,[36,0],[\"quill/toolbar/select\"],[[\"type\"],[\"size\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"strike\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"underline\"]]],[30,[36,0],[\"quill/toolbar/button\"],[[\"type\"],[\"video\"]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});