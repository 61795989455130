define("ember-svg-jar/inlined/training", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"evenodd\">\n    <path fill=\"currentColor\" fill-opacity=\"0.5\" d=\"M42.385 30.038a2.59 2.59 0 00-.323.02c-15.066.17-27.282 12.476-27.282 27.58 0 15.21 12.385 27.595 27.595 27.595 14.364 0 26.194-11.047 27.468-25.086H87.53a2.509 2.509 0 002.498-2.499V32.557a2.509 2.509 0 00-2.498-2.519H42.385z\"/>\n    <path fill=\"currentColor\" fill-rule=\"nonzero\" d=\"M36.605 22.326a2.59 2.59 0 00-.324.02C21.216 22.516 9 34.82 9 49.926c0 15.21 12.384 27.595 27.595 27.595 14.364 0 26.194-11.047 27.467-25.086H81.75a2.509 2.509 0 002.499-2.5v-25.09a2.509 2.509 0 00-2.499-2.519H36.605zm.117 5.018h12.416v7.535a2.509 2.509 0 002.499 2.499h15.057a2.509 2.509 0 002.518-2.499v-7.535h10.04v20.073h-17.15a2.509 2.509 0 00-2.9 2.083 2.51 2.51 0 00-.034.51c-.046 12.46-10.102 22.494-22.573 22.494-12.5 0-22.578-10.079-22.578-22.578 0-12.499 10.079-22.578 22.578-22.578.042 0 .085-.002.127-.004zm17.438 0h10.035v5.017H54.16v-5.017zM36.605 37.378c-6.898 0-12.558 5.66-12.558 12.558s5.66 12.538 12.558 12.538c6.897 0 12.533-5.64 12.533-12.538 0-6.898-5.636-12.558-12.533-12.558zm0 5.022c4.186 0 7.516 3.35 7.516 7.536a7.472 7.472 0 01-7.516 7.516c-4.186 0-7.54-3.33-7.54-7.516a7.509 7.509 0 017.54-7.536z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});