define("ember-svg-jar/inlined/splash.carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"currentColor\" fill-rule=\"evenodd\">\n    <path d=\"M0 0h368v207H0z\" opacity=\".197\"/>\n    <g transform=\"translate(162 183)\">\n      <rect width=\"8\" height=\"8\" rx=\"4\"/>\n      <rect width=\"8\" height=\"8\" x=\"24\" opacity=\".302\" rx=\"4\"/>\n      <rect width=\"8\" height=\"8\" x=\"36\" opacity=\".302\" rx=\"4\"/>\n      <rect width=\"8\" height=\"8\" x=\"12\" opacity=\".302\" rx=\"4\"/>\n    </g>\n    <path fill-rule=\"nonzero\" d=\"M224 129.333h-80L170.667 76l20 40L204 89.333z\"/>\n  </g>\n",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 368 207"
    }
  };
});