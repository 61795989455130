define("ember-svg-jar/inlined/app.photo-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <path d=\"M25,0 L0,0 L0,25\" fill=\"none\" stroke=\"#71717A\" stroke-width=\"3\"/>\n  <path d=\"M0,75 L0,100 L25,100\" fill=\"none\" stroke=\"#71717A\" stroke-width=\"3\"/>\n  <path d=\"M75,100 L100,100 L100,75\" fill=\"none\" stroke=\"#71717A\" stroke-width=\"3\"/>\n  <path d=\"M100,25 L100,0 L75,0\" fill=\"none\" stroke=\"#71717A\" stroke-width=\"3\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100",
      "width": "50px"
    }
  };
});