define("ember-svg-jar/inlined/night", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M57.54 9.151a40.8 40.8 0 00-27.046 10.235c-7.4 6.542-12.178 15.512-13.455 25.257a1.925 1.925 0 103.817.5C23.258 26.82 39.028 13.002 57.54 13.002c20.402 0 37 16.598 37 36.999 0 20.401-16.598 36.999-37 36.999-14.445 0-27.66-8.493-33.668-21.636a1.925 1.925 0 00-3.502 1.6A40.906 40.906 0 0035.178 84.19a40.686 40.686 0 0022.361 6.66c22.524 0 40.849-18.325 40.849-40.849 0-22.524-18.325-40.849-40.849-40.849z\"/>\n      <path d=\"M36.747 67.048a1.925 1.925 0 10-2.975 2.443c5.867 7.147 14.53 11.246 23.767 11.246 16.949 0 30.737-13.789 30.737-30.737S74.488 19.263 57.54 19.263c-16.948 0-30.736 13.789-30.736 30.737 0 3.248.504 6.448 1.5 9.51a1.925 1.925 0 003.66-1.19 26.87 26.87 0 01-1.31-8.32c0-14.826 12.061-26.887 26.887-26.887 14.825 0 26.887 12.061 26.887 26.887 0 14.825-12.062 26.887-26.887 26.887a26.81 26.81 0 01-20.793-9.839z\"/>\n      <path d=\"M57.54 33.22a1.925 1.925 0 00-1.925 1.926v14.896c0 1.062.861 1.924 1.924 1.924h12.89a1.925 1.925 0 000-3.85H59.463v-12.97a1.925 1.925 0 00-1.925-1.925z\"/>\n    </g>\n    <path fill=\"currentColor\" d=\"M41.638 60.99c-14.642-3.556-23.63-18.308-20.074-32.95a27.195 27.195 0 014.542-9.747c-11.9.437-22.547 8.69-25.495 20.831-3.556 14.642 5.432 29.394 20.073 32.95 11.01 2.672 22.08-1.746 28.408-10.327-2.45.09-4.954-.15-7.454-.758z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});