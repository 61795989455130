define("ember-svg-jar/inlined/park", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n  <g fill=\"none\" fill-rule=\"nonzero\">\n    <g fill=\"currentColor\" fill-opacity=\"0.5\">\n      <path d=\"M45.874 38.756a12.017 12.017 0 001.651-6.098c0-6.667-5.404-12.072-12.072-12.072-6.667 0-12.072 5.405-12.072 12.072 0 2.224.602 4.309 1.652 6.098a14.798 14.798 0 00-4.422 10.57c0 8.197 6.645 14.843 14.842 14.843 8.198 0 14.843-6.646 14.843-14.843 0-4.137-1.692-7.878-4.422-10.57z\"/>\n      <path d=\"M13.713 62.783c-6.503 0-11.775-5.272-11.775-11.775V21.323c0-6.504 5.272-11.776 11.775-11.776s11.775 5.272 11.775 11.776v29.685c0 6.503-5.272 11.775-11.775 11.775z\"/>\n    </g>\n    <path fill=\"currentColor\" d=\"M97.278 86.577h-5.975v-5.38h3.455c1.07 0 1.938-.868 1.938-1.938v-9.64c0-1.07-.867-1.938-1.938-1.938H88.89v-4.675h3.932c1.07 0 1.937-.868 1.937-1.938v-9.64c0-1.07-.867-1.937-1.937-1.937h-3.932v-1.847a1.938 1.938 0 10-3.875 0v1.847H58.342v-1.847a1.938 1.938 0 10-3.875 0v1.847h-3.932c-1.07 0-1.938.867-1.938 1.938v9.64c0 1.07.868 1.937 1.938 1.937h3.932v4.675h-5.87c-1.07 0-1.937.868-1.937 1.938v9.64c0 1.07.867 1.937 1.937 1.937h3.456v5.38H37.39V54.484l4.512-4.512a1.938 1.938 0 00-2.74-2.741L37.39 49v-4.623a1.938 1.938 0 10-3.876 0v42.2H15.651V36.164a1.938 1.938 0 10-3.876 0v50.412H1.938a1.938 1.938 0 100 3.876h95.34a1.938 1.938 0 100-3.876zm-44.805-33.21h38.41v5.763h-38.41v-5.764zm5.87 9.639h26.67v4.675h-26.67v-4.675zm-7.808 8.551h42.286v5.764H50.535v-5.764zm5.393 9.64h31.5v5.38h-31.5v-5.38z\"/>\n  </g>\n",
    "attrs": {
      "viewBox": "0 0 100 100"
    }
  };
});