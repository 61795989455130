define("ember-moment/services/moment", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    _timeZone: null,
    locale: null,
    localeOptions: null,
    defaultFormat: null,
    __config__: Ember.computed(function () {
      let config = Ember.getOwner(this).factoryFor('config:environment').class || {};
      return config.moment || {};
    }).readOnly(),
    timeZone: Ember.computed('_timeZone', {
      get() {
        return this._timeZone;
      },
      set(propertyKey, timeZone) {
        if (!_momentTimezone.default.tz) {
          /* eslint-disable no-console */
          console.warn('[ember-moment] attempted to set timezone, but moment-timezone is not setup.');
          return;
        }
        Ember.set(this, '_timeZone', timeZone);
        return timeZone;
      }
    }),
    setLocale(locale) {
      this.changeLocale(locale);
    },
    updateLocale(locale, localeOptions = {}) {
      this.changeLocale(locale, localeOptions);
    },
    changeLocale(locale, localeOptions = {}) {
      Ember.setProperties(this, {
        locale,
        localeOptions
      });
      _momentTimezone.default.updateLocale(locale, localeOptions);
      this.trigger('localeChanged', locale);
    },
    setTimeZone(timeZone) {
      this.changeTimeZone(timeZone);
    },
    changeTimeZone(timeZone) {
      Ember.set(this, 'timeZone', timeZone);
      this.trigger('timeZoneChanged', timeZone);
    },
    isMoment(obj) {
      return _momentTimezone.default.isMoment(obj);
    },
    moment() {
      let momentObj = (0, _momentTimezone.default)(...arguments);
      let {
        locale,
        timeZone
      } = this;
      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }
      if (timeZone && momentObj.tz) {
        momentObj = momentObj.tz(timeZone);
      }
      return momentObj;
    },
    utc() {
      let momentObj = _momentTimezone.default.utc(...arguments);
      let {
        locale
      } = this;
      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }
      return momentObj;
    }
  });
});